import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from "react-slick";
import styles from "../../styles/Store.module.css";
import axios from "axios";
import SingleCustomAutocomplete from "../CustomAutocomplete/SingleCustomAutocomplete";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

const ProductsSlider = (props) => {
  const mobile = useMediaQuery("(max-width:600px)");
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [items, setItems] = useState([]);
  // const [drivers, setDrivers] = useState([]);
  // const [teams, setTeams] = useState([]);
  // const [itemsDiscounted, setItemsDiscounted] = useState([]);
  // const [itemsHot, setItemsHot] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  // const [filterForm, setFilterForm] = useState({
  //   driver_id: null,
  //   team_id: null,
  // });
  const slidesToShow = props.slidesToShow ? props.slidesToShow : 4.5;
  const slidesToShowMobile = props.slidesToShowMobile
    ? props.slidesToShowMobile
    : 2.5;
  const settings = {
    infinite: false,
    speed: 300,
    slidesToShow: mobile ? slidesToShowMobile : slidesToShow,
    initialSlide: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      console.log({ oldSlide: current, activeSlide: next });
    },
    afterChange: (current) => {
      setActiveSlide((prev) => current);
    },
  };
  const sliderRef = createRef();

  const getItems = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/items/get`, {
        params: {
          name: "Store",
          type: props.type,
          driver_id: props.filterForm.driver_id,
          team_id: props.filterForm.team_id,
        },
      })
      .then(function (response) {
        let data = response.data;

        setItems(data);
      })
      .catch((error) => console.log(error.response));
  };

  useEffect(() => {
    getItems();
  }, [props.filterForm.driver_id, props.filterForm.team_id]);

  return (
    <div style={i18n.language === "ar" ? { direction: "rtl" } : null}>
      {props.title != null ? (
        <h1 className={styles.sliderHeader}>{props.title}</h1>
      ) : null}

      <div className={styles.sliderContainer}>
        {mobile ? (
          <div>
            {items.map((item, index) => (
              <div
                key={index}
                // className={styles.slide}
                className={`${styles.slide} ${
                  index == 0 ? styles.firstSlide : ""
                }`}
              >
                <div className={styles.slideSection}>
                  <img
                    style={{ height: "350px" }}
                    src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/itemsImages/${item.image}`}
                    width={"100%"}
                    height={"100%"}
                  />
                  <div className={styles.slideBtn}>
                    <button onClick={() => navigate(`/store-items/${item.id}`)}>
                      {t("view_item")}
                    </button>
                  </div>
                </div>
                <div style={{ paddingLeft: "10px" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === "ar"
                          ? item.arabic_description
                          : item.description,
                    }}
                  />
                  <div className={styles.priceFooter}>
                    <h5>
                      {item.discounted_price ? "$" + item.discounted_price : ""}
                    </h5>
                    <h4 style={{ margin: 0, marginBottom: 5 }}>
                      ${item.selling_price}
                    </h4>
                  </div>
                </div>
              </div>
            ))}{" "}
          </div>
        ) : (
          <>
            <Slider ref={sliderRef} {...settings} className={styles.slider}>
              <div></div>
              {items.map((item, index) => (
                <div
                  key={index}
                  // className={styles.slide}
                  className={`${styles.slide} ${
                    index == 0 ? styles.firstSlide : ""
                  }`}
                >
                  <div className={styles.slideSection}>
                    <img
                      style={{ height: "350px" }}
                      src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/itemsImages/${item.image}`}
                      width={"100%"}
                      height={"100%"}
                    />
                    <div className={styles.slideBtn}>
                      <button
                        onClick={() => navigate(`/store-items/${item.id}`)}
                      >
                        {t("view_item")}
                      </button>
                    </div>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          i18n.language === "ar"
                            ? item.arabic_description
                            : item.description,
                      }}
                    />
                    <div className={styles.priceFooter}>
                      <h5>
                        {item.discounted_price
                          ? "$" + item.discounted_price
                          : ""}
                      </h5>
                      <h4 style={{ margin: 0, marginBottom: 5 }}>
                        ${item.selling_price}
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className={styles.sliderArrows}>
              {/* {activeSlide > 0 ? ( */}
              <button
                className={styles.sliderArrowLeft}
                onClick={() => sliderRef?.current?.slickPrev()}
              >
                <ArrowBackIcon sx={{ fontSize: "3vw" }} />
              </button>
              {/* // ) : (
          //   <div> </div>
          // )} */}
              <button
                className={styles.sliderArrowRight}
                onClick={() => sliderRef?.current?.slickNext()}
              >
                <ArrowForwardIcon sx={{ fontSize: "3vw" }} />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductsSlider;
