import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import styles from "../../../styles/new/SchedulesDetails.module.css";
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';

const RaceList = (props) => {
    const [data, setData] = useState(props.data);
    const { t, i18n } = useTranslation();

    return (
        <>
            <Grid container>
                <Grid item xs={8} md={8}>
                    <div className={styles.detailsCardBodyLeft}>
                        <div className={styles.detailsCardBodyLeft1}>
                            <div className={styles.detailsCardBodyLeft1_content}>
                                <span>{
                                    data.day
                                }</span>
                                <span> {data.month}</span>
                            </div>
                        </div>
                        <div className={styles.detailsCardBodyLeft2}>
                            <div className={styles.detailsCardBodyLeft2_content}>
                                <lu>
                                    <li style={i18n.language ==="ar" ? {fontSize:'18px'} : null}>
                                        {
                                            i18n.language === "ar" ?
                                                data.arabic_race_name
                                                :
                                                data.race_name
                                        }
                                    </li>
                                </lu>
                                <div>
                                    {data.time}
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default RaceList;
