import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import video from "../assets/videos/careers.mp4";
import styles from "../styles/Pages.module.css";
import axios from "axios";
import { CircularProgress, Grid } from "@mui/material";
import Values from "../components/AboutUs/Values";
import { useTranslation } from "react-i18next";
import Employees from "../components/AboutUs/Employees";

const AboutUs = () => {
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState("");
  const [videoSource, setVideoSource] = useState("");
  const { t, i18n } = useTranslation();

  const videoRef = createRef();
  let navigate = useNavigate();
  const [width, setWidth] = useState(100);

  const handleVideo = (event) => {
    let currentTime = event.target.currentTime;
    let duration = event.target.duration;
    let perc = 100 - (currentTime * 100) / duration;
    setWidth(perc);
  };

  useEffect(() => {
    videoRef?.current?.addEventListener("timeupdate", handleVideo);

    // cleanup this component
    return () => {
      videoRef?.current?.removeEventListener("timeupdate", handleVideo);
    };
  }, []);

  const getPageData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "About-us",
        },
      })
      .then(function (response) {
        const src = `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.video}`;
        setVideoSource((prev) => src);
        setPageData((prev) => response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error.response);
        setLoading(false);
      });
  };
  useEffect(() => {
    getPageData();
  }, []);

  return (
    <div className={styles.main}>
      {loading ? (
        <CircularProgress />
      ) : (
        <></>
        // <video
        //   style={{ objectFit: "cover" }}
        //   // controls
        //   ref={videoRef}
        //   width={"100%"}
        //   height={"100%"}
        //   preload="auto"
        //   autoPlay={true}
        //   playsInline={true}
        //   muted
        //   // loop
        //   // onEnded={changePage}
        // >
        //   <source src={videoSource} type="video/mp4" />
        // </video>
      )}

      {/* <div className={styles.countdown}>
        <span style={{ width: `${width}%` }}></span>
      </div> */}

      <div className={styles.aboutusHero}>
        <h1>ABOUT US</h1>
      </div>
      <div className={styles.aboutusSection}>
        {/* <h1>our vision and Mission</h1>
        <h2>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
          volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
          ullamcorper
        </h2> */}

        <div
          dangerouslySetInnerHTML={{
            __html:
              i18n.language === "ar" ?
                pageData.arabic_text1
                :
                pageData.text1
          }}
        ></div>
      </div>
      <Employees />
      <div style={{padding:'100px',backgroundColor:'#F9F9F9'}}>
        <Values />

      </div>
    </div>
  );
};

export default AboutUs;
