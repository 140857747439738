import { Grid } from "@mui/material";
import article1 from "../../assets/images/article1.png";
import article2 from "../../assets/images/article2.png";
import article3 from "../../assets/images/article3.png";
import React from "react";
import styles from "../../styles/ArticlesBrief.module.css";
import { useTranslation } from "react-i18next";

const ArticlesBrief = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ padding: 50, background: "#F0F0F0" }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        spacing={2}
      >
        <Grid
          item
          container
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <a href={`${props.data.link1 != '' ? props.data.link1 : '#'}`} target="_blank" style={{ textDecoration: 'none' }}>
            <div className={styles.articleCard}>
              <img src={article1} />
              {
                i18n.language === "ar"
                  ?
                  <p>
                    مقاطع الفيديو التي تغطي مجموعة من الموضوعات التعليمية ذات الصلة بـ فورمولا 1
                  </p>
                  :
                  <p>
                    Videos that cover a range of educational topics related to
                    Formula1
                  </p>
              }

            </div>
          </a>

        </Grid>

        <Grid
          item
          container
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <a href={`${props.data.link2 ? props.data.link2 : '#'}`} target="_blank" style={{ textDecoration: 'none' }}>
            <div className={styles.articleCard}>
              <img src={article2} />
              {
                i18n.language === "ar"
                  ?
                  <p>
                    مينا جي بي هي ضيف متحدث في عدة ملفات البودكاست تركز على الفورمولا واحد
                  </p>
                  :
                  <p>
                    Mena GP is a guest speaker on multiple Formula One-focused
                    podcasts
                  </p>
              }
            </div>
          </a>
        </Grid>

        <Grid
          item
          container
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <a href={`${props.data.link3 ? props.data.link3 : '#'}`} target="_blank" style={{ textDecoration: 'none' }}>
            <div className={styles.articleCard}>
              <img src={article3} />
              {
                i18n.language === "ar"
                  ?
                  <p>
                   نهاية الاسبوع لمناقشة مسار السباق وتوقعات الموسم 
                  </p>
                  :
                  <p>
                    Twitter weekend spaces to discuss the course of the race and
                season expectations
                  </p>
              }
            </div>
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default ArticlesBrief;
