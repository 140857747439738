import React, { useState, useEffect, createRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Grid from "@mui/material/Grid";
import styles from "../../styles/TeamCardDetails.module.css";
import { useParams } from "react-router-dom";
import ImageShow from "./ImageShow";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductsSlider from "../../components/Store/ProductsSlider";

const TeamCardDetails = (props) => {
  const { season_id } = useParams();
  const { id } = useParams();
  const [show, setShow] = useState(true);
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [filterForm, setFilterForm] = useState({
    team_id: id,
  });
  useEffect(() => {
    const getDrivers = () => {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/masterData/teams/getBySeasonDetails`,
          {
            params: {
              season_id: season_id,
              id: id,
            },
          }
        )
        .then(function (response) {
          setData(response.data);
          setShow(false);
        })
        .catch(function (error) {
          console.log(error.response);
          setShow(false);
        });
    };
    getDrivers();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "110vh" }}
      >
        <div className={styles.container}>
          <Grid container>
            {show ? (
              <CircularProgress />
            ) : (
              <>
                <Grid
                  item
                  md={6}
                  xs={12}
                  style={{ borderBottom: "1px solid #EAECEE" }}
                >
                  <table className={styles.tableRight} style={props.language === "ar" ? { direction: "rtl", textAlign: "center" } : null}>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'}>
                        <img
                          src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/teamsImages/${data.data.profile_image}`}
                          // src={"https://www.formula1.com/content/fom-website/en/teams/Red-Bull-Racing/_jcr_content/logo.img.jpg/1645620474276.jpg"}
                          width={"190"}
                          height={"140"}
                          loading={"lazy"}
                        />
                      </th>
                      <th style={{ color: "red", fontSize: "35px" }}>
                        {t('official_word')}
                      </th>
                    </tr>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'} className={styles.leftTH}>
                        {t('team_word')}
                      </th>
                      <th width="50%" align="center" className={styles.rightTH}>
                        {data.data.name}
                      </th>
                    </tr>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'} className={styles.leftTH}>
                        {t('base_word')}
                      </th>
                      <th width="50%" align="center" className={styles.rightTH}>
                        {data.data.base}
                      </th>
                    </tr>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'} className={styles.leftTH}>
                        {t('chief_word')}
                      </th>
                      <th width="50%" align="center" className={styles.rightTH}>
                        {data.data.team_chief}
                      </th>
                    </tr>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'} className={styles.leftTH}>
                        {t('tech_chief_word')}
                      </th>
                      <th width="50%" align="center" className={styles.rightTH}>
                        {data.data.technical_chief}
                      </th>
                    </tr>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'} className={styles.leftTH}>
                        {t('chassis_word')}
                      </th>
                      <th width="50%" align="center" className={styles.rightTH}>
                        {data.data.chassis}
                      </th>
                    </tr>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'} className={styles.leftTH}>
                        {t('power_unit_word')}
                      </th>
                      <th width="50%" align="center" className={styles.rightTH}>
                        {data.data.power_unit}
                      </th>
                    </tr>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'} className={styles.leftTH}>
                        {t('first_team_entry_word')}
                      </th>
                      <th width="50%" align="center" className={styles.rightTH}>
                        {data.data.first_team_entry}
                      </th>
                    </tr>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'} className={styles.leftTH}>
                        {t('world_champion_word')}
                      </th>
                      <th width="50%" align="center" className={styles.rightTH}>
                        {data.data.world_champ}
                      </th>
                    </tr>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'} className={styles.leftTH}>
                        {t('highest_race_word')}
                      </th>
                      <th width="50%" align="center" className={styles.rightTH}>
                        {data.data.highest_race_finish}
                      </th>
                    </tr>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'} className={styles.leftTH}>
                        {t('pole_position_word')}
                      </th>
                      <th width="50%" align="center" className={styles.rightTH}>
                        {data.data.pole_positions}
                      </th>
                    </tr>
                    <tr>
                      <th width="50%" align={props.language === "ar" ? 'center' : 'left'} className={styles.leftTH}>
                        {t('fastest_laps_word')}
                      </th>
                      <th width="50%" align="center" className={styles.rightTH}>
                        {data.data.fastest_laps}
                      </th>
                    </tr>
                  </table>
                </Grid>
                <Grid container item xs={12} md={6}>
                  <Grid
                    item
                    md={6}
                    xs={6}
                    style={{ border: "1px solid #EAECEE" }}
                    className={styles.driversPart}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/driversImages/${data.driver1_image}`}
                      width={"100%"}
                      height={"450px"}
                      loading={"lazy"}
                      style={{ objectFit: "cover", objectPosition: "50% 50%" }}
                    />
                    <div className={styles.subImage}>
                      <span>{data.driver1_number}</span>
                      <img
                        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/countriesImages/${data.driver1_country_image}`}
                        height={"35"}
                        width={"45"}
                        style={{ borderRadius: "10px" }}
                        loading={"lazy"}
                      />
                      <p>
                        {
                          props.language === "ar" ?
                            <>
                              {data.driver1_firstname_arabic} {data.driver1_lastname_arabic}
                            </>
                            :
                            <>
                              {data.driver1_firstname} {data.driver1_lastname}
                            </>
                        }
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={6}
                    style={{ border: "1px solid #EAECEE" }}
                    className={styles.driversPart}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/driversImages/${data.driver2_image}`}
                      width={"100%"}
                      height={"450px"}
                      loading={"lazy"}
                      style={{ objectFit: "cover" }}
                    />
                    <div className={styles.subImage}>
                      <span>{data.driver2_number}</span>
                      <img
                        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/countriesImages/${data.driver2_country_image}`}
                        height={"35"}
                        width={"45"}
                        style={{ borderRadius: "10px" }}
                        loading={"lazy"}
                      />
                      <p>
                        {
                          props.language === "ar" ?
                            <>
                              {data.driver2_firstname_arabic} {data.driver2_lastname_arabic}
                            </>
                            :
                            <>
                              {data.driver2_firstname} {data.driver2_lastname}
                            </>
                        }
                      </p>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} className={styles.showPart}>
                  <ImageShow data={data} path={"teamsImages"} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className={styles.containerPart2}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          props.language === "ar" ?
                            data.data.arabic_description
                            :
                            data.data.description
                      }}
                    ></div>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <ProductsSlider
                    filterForm={filterForm}
                    title={t('shop_now')}
                    slidesToShowMobile={2}
                    slidesToShow={3}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Grid>
    </>
  );
}
export default TeamCardDetails;
