import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import video from "../assets/videos/group.mp4";
import styles from "../styles/Pages.module.css";
const Group = () => {
  const videoRef = createRef();
  let navigate = useNavigate();
  const [width, setWidth] = useState(100);

  const handleVideo = (event) => {
    let currentTime = event.target.currentTime;
    let duration = event.target.duration;

    // console.log("currentTime", currentTime);
    // console.log("duration", duration);
    let perc = 100 - (currentTime * 100) / duration;
    setWidth(perc);
  };

  useEffect(() => {
    videoRef?.current?.addEventListener("timeupdate", handleVideo);

    // cleanup this component
    return () => {
      videoRef?.current?.removeEventListener("timeupdate", handleVideo);
    };
  }, []);

  return (
    <div className={styles.main}>
      {/* <video
        ref={videoRef}
        width={"100%"}
        // height={"100%"}
        preload="auto"
        autoPlay={true}
        playsInline={true}
        muted
        // loop
        // onEnded={changePage}
      >
        <source src={video} type="video/mp4" />
      </video> */}
      <div className={styles.countdown}>
        <span style={{ width: `${width}%` }}></span>
      </div>
    </div>
  );
};

export default Group;
