import React, { useState, useEffect, createRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Grid from "@mui/material/Grid";
import ADS from "../../components/NewsArticles/ADS";
import styles from "../../styles/new/MenaTv.module.css";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import headerBackground from '../../assets/images/tv_hedear.png'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from "react-slick";


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={styles.ArrowForward}
            onClick={onClick}
        >
            <ArrowForwardIcon sx={{ fontSize: "3vw" }} />
        </button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={styles.ArrowBack}
            onClick={onClick}
        >
            <ArrowBackIcon sx={{ fontSize: "3vw" }} />
        </button>
    );
}


const MenaTv = (props) => {
    const mobile = useMediaQuery("(max-width:800px)");
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState({});
    const [dataRand, setDataRand] = useState([]);
    const [dataMost, setDataMost] = useState([]);
    const [dataTop, setDataTop] = useState([]);
    let counter = 0;
    let counter2 = 0;
    let counter3 = 0;
    const [id, setId] = useState('');
    const [visible, setVisible] = useState(false);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: mobile ? 1:3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const getPageData = () => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
                params: {
                    name: "Team",
                },
            })
            .then(function (response) {
                setPageData((prev) => response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error.response);
                setLoading(false);
            });
    };

    const getData = () => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/data/videos/getVideos`, {
                params: {},
            })
            .then(function (response) {
                setDataRand(response.data.data_rand);
                setDataTop(response.data.data_top);
                setDataMost(response.data.data_most);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error.response);
                setLoading(false);
            });
    };

    useEffect(() => {
        getPageData();
        getData();
    }, []);


    return (
        <>
            <Grid container style={{ backgroundColor: '#F0F0F0' }}>
                <div className={styles.header} style={{ backgroundImage: `url(${headerBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                </div>
                <ADS />
                <div className={styles.body}>
                    <Grid container spacing={2} style={{ marginBottom: '80px' }}>
                        {
                            dataRand.map((e, index) => {

                                for (let i = counter; i <= counter; i++) {
                                    counter = i + 1;
                                    return (
                                        <>
                                            {
                                                i == 0 ?
                                                    <Grid item xs={12} md={6} key={index}>
                                                        <video width={'100%'} height="620" style={{ objectFit: 'cover' }} controls>
                                                            <source src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/menaTvVideos/${e.video}`} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </Grid>
                                                    :
                                                    null
                                            }
                                        </>
                                    )
                                }
                            })
                        }
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>

                                {

                                    dataRand.map((e, index) => {
                                        for (let i = counter2; i <= counter2; i++) {
                                            counter2 = i + 1;
                                            return (
                                                <>
                                                    {
                                                        i == 1 ?
                                                            <Grid item xs={12} md={12} key={index}>
                                                                <video width={'100%'} height="300" style={{ objectFit: 'cover' }} controls>
                                                                    <source src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/menaTvVideos/${e.video}`} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </Grid>
                                                            :
                                                            null
                                                    }
                                                </>
                                            )
                                        }
                                    })
                                }
                                {

                                    dataRand.map((e, index) => {
                                        for (let i = counter3; i <= counter3; i++) {
                                            counter3 = i + 1;
                                            return (
                                                <>
                                                    {
                                                        i > 1 ?
                                                            <Grid item xs={6} md={6} key={index}>
                                                                <video width={'100%'} height="300" style={{ objectFit: 'cover' }} controls>
                                                                    <source src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/menaTvVideos/${e.video}`} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </Grid>
                                                            :
                                                            null
                                                    }
                                                </>
                                            )
                                        }
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <div style={{
                                fontFamily: 'League Spartan',
                                fontSize: '36px',
                                fontStyle: 'normal',
                                lineHeight: '33px',
                                textTransform: 'uppercase',
                                color: '#BF2A30'
                            }}>
                                Latest videos
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div className={styles.subHeaderLine}>
                                <div className={styles.subHeaderLineContent}>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div className={styles.videoSlider}>
                                <Slider {...settings} className={styles.slider}>
                                    {
                                        dataTop.map((e, index) => (
                                            <div className={styles.sliderContent} key={index}>
                                                <video width={'90%'} height="300" style={{ objectFit: 'cover' }} controls>
                                                    <source src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/menaTvVideos/${e.video}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <div style={{
                                fontFamily: 'League Spartan',
                                fontSize: '36px',
                                fontStyle: 'normal',
                                lineHeight: '33px',
                                textTransform: 'uppercase',
                                color: '#BF2A30'
                            }}>
                                most watched
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div className={styles.subHeaderLine}>
                                <div className={styles.subHeaderLineContent}>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div className={styles.videoSlider}>
                                <Slider {...settings} className={styles.slider}>
                                    {
                                        dataMost.map((e, index) => (
                                            <div className={styles.sliderContent} key={index}>
                                                <video width={'90%'} height="300" style={{ objectFit: 'cover' }} controls>
                                                    <source src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/menaTvVideos/${e.video}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </>
    );
};

export default MenaTv;
