import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import FormControl from '@material-ui/core/FormControl';
import { FormControl } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import img from "../../assets/images/arrow-down.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#BF2A30",
    // width: "234px !important",

    "& . MuiFormLabel-filled": {
      // color: "black",
    },

    // focused: {
    //   border: 0,
    // },

    "& .MuiInputLabel-root.Mui-focused ": {
      transform: "translate(-30px, -20px) scale(0.75)",
      color: "#BF2A30 !important",
    },

    "& .MuiInputLabel-root": {
      color: "white",
      fontFamily: "League Spartan",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "22px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#FFFFFF",
      margin: "auto",
    },

    "& label": {
      paddingLeft: "45px !important",
    },

    "& .MuiAutocomplete-endAdornment": {
      "& .MuiAutocomplete-popupIndicator": {
        "& svg": {
          display: "none",
        },
      },
    },
  },

  hasClearIcon: {
    "& label": {
      display: "none",
      transform: "translate(-30px, -20px) scale(0.75)",
      color: "#BF2A30 !important",
    },
  },

  endAdornment: { paddingRight: "32px !important" },
  popupIndicator: {
    background: `url(${img})`,
    width: 20,
    height: 20,
    backgroundRepeat: "no-repeat",
  },

  clearIndicator: {
    position: "absolute !important",
    right: "0px !important",
  },

  inputRoot: {
    "& input": {
      color: "white",
      fontFamily: "League Spartan",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#FFFFFF",
      margin: "auto",
    },

    "& .MuiInputBase-formControl": {
      textAlign: "center",
    },
  },
}));

const SingleCustomAutocomplete = (props) => {
  const classes = useStyles();

  const filedName = props.filedName;
  const fieldLabel = props.label;
  const list = props.list;
  const fieldValue = props.value;
  const listKey = props.listKey;
  const listDescription = props.description;
  const customOnChange = props.customOnChange;
  const loading = props.loading || null;

  const optionForLabel = (label) => {
    if (typeof label === "object" && !Array.isArray(label) && label !== null) {
      return label[listDescription];
    }
    let toReturn = list.find((source) => {
      return source[listKey] == label;
    });
    if (toReturn) {
      return toReturn[listDescription];
    }
    return `${label}`;
  };

  const getOptionSelected = (option, value) => {
    if (
      typeof option === "object" &&
      !Array.isArray(option) &&
      option !== null
    ) {
      return option[listKey] == value;
    }
    return false;
  };

  const update = (event, value) => {
    if (loading) {
      console.log("on change");
      customOnChange();
      return;
    }
    if (value === null) {
      customOnChange(null, filedName, event);
      return;
      // }else if( typeof value === 'string'){
      //     setValue(optionForLabel(value))
    } else {
      customOnChange(value[listKey], filedName, value[listDescription], event);
    }
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <Autocomplete
        classes={classes}
        loading={loading}
        disabled={props.disabled ? props.disabled : false}
        name={filedName}
        options={list} // Options List
        value={fieldValue}
        onChange={update}
        getOptionLabel={optionForLabel}
        getOptionSelected={getOptionSelected}
        style={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label={fieldLabel} />}
      />
    </FormControl>
  );
};

SingleCustomAutocomplete.propTypes = {
  filedName: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  list: PropTypes.any.isRequired,
  value: PropTypes.any,
  listKey: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  customOnChange: PropTypes.any.isRequired,
  disabled: PropTypes.any,
  loading: PropTypes.any,
};

export default SingleCustomAutocomplete;
