import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import image from "../../assets/images/sharewithus.png";
import styles from "../../styles/new/ShareWithUs.module.css";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const [imgSrc, setImgSrc] = useState("");
  const { t, i18n } = useTranslation();

  const getPageData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "ShareWithUs",
        },
      })
      .then(function (response) {
        const src = `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image1}`;
        setImgSrc((prev) => src);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <section
      className={styles.heroSection}
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        background: `linear-gradient(269.86deg, rgba(119, 75, 77, 0.4) 16.35%, rgba(122, 65, 67, 0.4) 90.92%), url(${imgSrc})`,
      }}
    >
      <div>
        <h1>{t('share_with_us')}</h1>
        <h2>{t('share_with_us_paraghraph_1')}</h2>
        <h2>{t('share_with_us_paraghraph_2')}</h2>
      </div>
    </section>
  );
};

export default Hero;
