import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const monthBox = {
  backgroundColor: '#CBD0D6',
  padding: '5px',
  width: '30px',
  color: 'black',
  borderRadius: '15px',
  textTransform: 'uppercase',
  opacity:'0.5',
  fontSize:'15px',
  height:'10px',
  lineHeight:'50%',
  width:'33px'
};
const numberMonth = {
  marginLeft:'10px',
  fontSize:'20px',
}

export default function CustomListItems(props) {
  const data = props.data;
  const language = props.language;
  return (
    <List sx={{ width: '100%', maxWidth: '95%', bgcolor: 'background.paper', borderRadius: '10px', margin: '10px' }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar style={{ borderRight: '2px solid grey', borderRightStyle: 'dotted' }}>
          <div>
            <div style={numberMonth}>{data.day}</div>
            <div style={monthBox}>{data.month}</div>
          </div>

        </ListItemAvatar>
        <ListItemText
          style={{ marginLeft: '20px',marginTop:'1rem' }}
          primary={
            <React.Fragment>
              <Typography
                sx={{ fontSize: '20px', fontWeight: '900', lineHeight: '1rem' }}
              >
                {
                  language ==="ar" ? 
                  data.arabic_race_name
                  :
                  data.race_name
                }
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {data.time}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
}
