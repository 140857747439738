import { createSlice } from "@reduxjs/toolkit";

export const basketSlice = createSlice({
  name: "basket",
  initialState: {
    items: [],
  },
  reducers: {
    add: (state, action) => {
      let newItems = state.items.filter(
        (item) => item.id !== action.payload.id
      );
      newItems.push(action.payload);
      state.items = newItems;
    },
    remove: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
    },
    empty: (state) => {
      state.items = [];
    },
    increase: (state, action) => {
      state.items = state.items.map((item) =>
        item.id == action.payload.id
          ? { ...item, buy: item.buy + 1 }
          : { ...item }
      );
    },
  },
});

export const { add, remove, empty, increase } = basketSlice.actions;

export default basketSlice.reducer;
