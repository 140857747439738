import React, { useState, useEffect, createRef } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import styles from "../styles/new/Standings.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import headerBackground from "../assets/images/teamHeader.png";
import ADS from "../components/NewsArticles/ADS";
// import video from "../assets/videos/racing.mp4";
import { generateArrayOfYears } from "../helpers/FixedData";
import SingleCustomAutocomplete from "../components/CustomAutocomplete/SingleCustomAutocomplete";
import { Link } from "react-router-dom";
import Footer2 from "../components/Layout/Footer2";
import { useTranslation } from "react-i18next";
import CustomDataTable from "../components/CustomDataTable/CustomDataTable";
import { LinearProgress } from "@mui/material";
import Header2 from "../components/Layout/Header2";

const key = [
  { key: "Races", arabic: "سباق" },
  { key: "Drivers", arabic: "سائق" },
  { key: "Teams", arabic: "فريق" },
];
const races_cols = ["Track", "Date", "Winner", "Car", "Laps", "Time"];
const arabic_races_cols = [
  "الحلبة",
  "التاريخ",
  "الفائز",
  "السيارة",
  "اللفات",
  "التوقيط",
];

const race_cols = ["No", "Driver", "Car", "Laps", "Time", "Points"];
const arabic_race_cols = [
  "الرقم",
  "السائق",
  "السيارة",
  "اللفات",
  "التوقيط",
  "النقاط",
];

const drivers_cols = ["Driver", "Nationality", "Car", "Points"];
const arabic_drivers_cols = ["السائق", "االجنسية", "السيارة", "النقاط"];

const driver_cols = ["Track", "Date", "Car", "Position", "Points"];
const arabic_driver_cols = ["الحلبة", "التاريخ", "السيارة", "المركز", "النقاط"];

const teams_cols = ["Team", "Points"];
const arabic_teams_cols = ["الفريق", "النقاط"];

const team_cols = ["Track", "Date", "Points"];
const arabic_team_cols = ["الحلبة", "التاريخ", "النقاط"];

const Standings = (props) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [colName, setColName] = useState("Races");
  const [cols2, setCols2] = useState([]);
  const [specificData, setSpecificData] = useState([{ id: 0, name: "All" }]);
  const [searching, setSearching] = useState(false);
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);
  const [seasons, setSeasons] = useState([]);

  const [filterForm, setFilterForm] = useState({
    // year: new Date().getFullYear(),
    season_id: seasons.length > 0 ? seasons[0].id : null,
    show: "Races",
    specific: 0,
  });

  const subTitle = {
    backgroundColor: "#FAF8F5",
    fontFamily: "monospace",
  };

  const getPageData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "Standing",
        },
      })
      .then(function (response) {
        setPageData((prev) => response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error.response);
        setLoading(false);
      });
  };
  useEffect(() => {
    getPageData();
  }, []);

  const yearLists = generateArrayOfYears();

  const getData = () => {
    setIsLoading(true);
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/standings/get`, {
        params: {
          season_id: filterForm.season_id,
          show: filterForm.show,
          specific: filterForm.specific,
        },
      })
      .then(function (response) {
        if (response.data != null) {
        console.log(response.data.data);
          setData(response.data.data);
          setCols2(response.data.cols);
          setColName(response.data.col_name);
          if (response.data.col_name == "races_cols") {
            if (props.language === "ar") {
              setCols(arabic_races_cols);
            } else {
              setCols(races_cols);
            }
          } else if (response.data.col_name == "race_cols") {
            if (props.language === "ar") {
              setCols(arabic_race_cols);
            } else {
              setCols(race_cols);
            }
          } else if (response.data.col_name == "drivers_cols") {
            if (props.language === "ar") {
              setCols(arabic_drivers_cols);
            } else {
              setCols(drivers_cols);
            }
          } else if (response.data.col_name == "driver_cols") {
            if (props.language === "ar") {
              setCols(arabic_driver_cols);
            } else {
              setCols(driver_cols);
            }
          } else if (response.data.col_name == "teams_cols") {
            if (props.language === "ar") {
              setCols(arabic_teams_cols);
            } else {
              setCols(teams_cols);
            }
          } else if (response.data.col_name == "team_cols") {
            if (props.language === "ar") {
              setCols(arabic_team_cols);
            } else {
              setCols(team_cols);
            }
          }
        }

        // setSearching(false);
        setIsLoading(false);

      })
      .catch(function (error) {
        console.log(error.response);
        // setSearching(false);
        setIsLoading(false);

      });
  };

  useEffect(() => {
    const getSeasons = () => {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/masterData/seasons/get`, {})
        .then(function (response) {
          setSeasons(response.data);
          setFilterForm({
            ...filterForm,
            season_id: response.data.length > 0 ? response.data[0].id : null,
          });
        })
        .catch(function (error) {
          console.log(error.response);
        });
    };
    getSeasons();
  }, []);
  const getSpecificData = () => {
    setSearching(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/data/standings/specific/get`,
        {
          params: {
            for: filterForm.show,
            season_id: filterForm.season_id,
          },
        }
      )
      .then(function (response) {
        console.log(response.data);

        setSpecificData((prev) => [{ id: 0, name: "All" }, ...response.data]);

        setSearching(false);
      })
      .catch(function (error) {
        console.log(error.response);
        setSearching(false);
      });
  };

  useEffect(() => {
    // getData();
    getSpecificData();
  }, [filterForm.specific]);

  useEffect(() => {
    if (filterForm.specific == 0) {
      getSpecificData();
      // getData();
    } else {
      setFilterForm((prev) => ({ ...prev, specific: 0 }));
    }
  }, [filterForm.show, filterForm.season_id]);

  const customOnChange = (newValue, name) => {
    setFilterForm((prev) => ({ ...prev, [name]: newValue }));
  };

  // useEffect(() => {
  //   getData();
  // }, [props.language]);

  useEffect(() => {
    getData();
  }, [filterForm.specific,filterForm.season_id,filterForm.show,props.language]);


  const columnsRaces = [
    {
      name: "no",
      label: "NO",
      options: {
        display: colName == "race_cols" ? true : false,
      },
    },
    {
      name: "track",
      label: "Track Name",
      options: {
        display:
          colName == "races_cols" && props.language != "ar" ? true : false,
      },
    },
    {
      name: "arabic_track_name",
      label: "الحلبة",
      options: {
        display:
          colName == "races_cols" && props.language === "ar" ? true : false,
      },
    },
    {
      name: "date",
      label: `${t("date_word")}`,
      options: {
        display: colName == "races_cols" ? true : false,
      },
    },
    {
      name: "winner",
      label: "Winner",
      options: {
        display:
          colName == "races_cols" && props.language != "ar" ? true : false,
      },
    },
    {
      name: "winner_arabic_name",
      label: "الفائز",
      options: {
        display:
          colName == "races_cols" && props.language === "ar" ? true : false,
      },
    },
    {
      name: "driver",
      label: "Driver",
      options: {
        display:
          colName == "race_cols" && props.language != "ar" ? true : false,
      },
    },
    {
      name: "arabic_driver_name",
      label: "السائق",
      options: {
        display:
          colName == "race_cols" && props.language === "ar" ? true : false,
      },
    },
    {
      name: "car",
      label: "Car",
      options: {
        display: props.language != "ar" ? true : false,
      },
    },
    {
      name: "car",
      label: "السيارة",
      options: {
        display: props.language === "ar" ? true : false,
      },
    },
    {
      name: "laps",
      label: `${t("laps_word")}`,
    },
    {
      name: "time",
      label: `${t("time_word")}`,
    },
    {
      name: "points",
      label: `${t("points_word")}`,
      options: {
        display: colName == "race_cols" ? true : false,
      },
    },
  ];

  const columnsDrivers = [
    {
      name: "driver",
      label: "Driver",
      options: {
        display:
          colName == "drivers_cols" && props.language != "ar" ? true : false,
      },
    },
    {
      name: "arabic_driver_name",
      label: "السائق",
      options: {
        display:
          colName == "drivers_cols" && props.language === "ar" ? true : false,
      },
    },
    {
      name: "nationality",
      label: "Nationality",
      options: {
        display:
          colName == "drivers_cols" && props.language != "ar" ? true : false,
      },
    },
    {
      name: "arabic_nationality",
      label: "االجنسية",
      options: {
        display:
          colName == "drivers_cols" && props.language === "ar" ? true : false,
      },
    },
    {
      name: "date",
      label: `${t("date_word")}`,
      options: {
        display: colName == "driver_cols" ? true : false,
      },
    },
    {
      name: "track",
      label: "Track Name",
      options: {
        display:
          colName == "driver_cols" && props.language != "ar" ? true : false,
      },
    },
    {
      name: "arabic_track_name",
      label: "الحلبة",
      options: {
        display:
          colName == "driver_cols" && props.language === "ar" ? true : false,
      },
    },
    {
      name: "car",
      label: "Car",
      options: {
        display: props.language !== "ar" ? true : false,
      },
    },
    {
      name: "car",
      label: "السيارة",
      options: {
        display: props.language === "ar" ? true : false,
      },
    },
    {
      name: "position",
      label: `${t("position_word")}`,
      options: {
        display: colName == "driver_cols" ? true : false,
      },
    },
    {
      name: "points",
      label: `${t("points_word")}`,
    },
  ];

  const columnsTeams = [
    {
      name: "team",
      label: "Team",
      options: {
        display:
          colName == "teams_cols" && props.language != "ar" ? true : false,
      },
    },
    {
      name: "arabic_team_name",
      label: "الفريق",
      options: {
        display:
          colName == "teams_cols" && props.language === "ar" ? true : false,
      },
    },
    {
      name: "track",
      label: "Track Name",
      options: {
        display:
          colName == "team_cols" && props.language != "ar" ? true : false,
      },
    },
    {
      name: "arabic_track_name",
      label: "الحلبة",
      options: {
        display:
          colName == "team_cols" && props.language === "ar" ? true : false,
      },
    },
    {
      name: "date",
      label: `${t("date_word")}`,
      options: {
        display: colName == "team_cols" ? true : false,
      },
    },
    {
      name: "points",
      label: `${t("points_word")}`,
    },
  ];

  const options = {
    filter: false,
    print: false,
    exports: false,
    download: false,
    viewColumns: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <LinearProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  return (
    <div style={{ paddingTop: "10px" }}>
      <Grid container>
        <div
          className={styles.header}
          style={{
            backgroundImage: `url(${headerBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <Grid item xs={12} md={12}>
          <div className={styles.subHeaderMenu}>
            <Header2 />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ padding: "20px", position: "relative", minHeight: "750px" }}
      >
        <ADS src={pageData.image1} />
        <Grid container style={{ marginLeft: "150px" }}>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            style={{ marginTop: "2rem" }}
          >
            <SingleCustomAutocomplete
              filedName="season_id"
              label={t("filter_year")}
              list={seasons}
              value={filterForm.season_id}
              listKey="id"
              description="name"
              customOnChange={customOnChange}
            />
          </Grid>

          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            style={{ marginTop: "2rem" }}
          >
            <SingleCustomAutocomplete
              filedName="show"
              label={`${t("sort_by_word")}`}
              list={key}
              value={filterForm.show}
              listKey="key"
              description={props.language === "ar" ? "arabic" : "key"}
              customOnChange={customOnChange}
            />
          </Grid>

          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
            style={{ marginTop: "2rem" }}
          >
            <SingleCustomAutocomplete
              filedName="specific"
              label={
                filterForm.show == "Races" && props.language != "ar"
                  ? "Races"
                  : filterForm.show == "Races" && props.language === "ar"
                    ? "السباقات"
                    : filterForm.show == "Drivers" && props.language != "ar"
                      ? "Drivers"
                      : filterForm.show == "Drivers" && props.language === "ar"
                        ? "السائقين"
                        : filterForm.show == "Teams" && props.language != "ar"
                          ? "Teams"
                          : filterForm.show == "Teams" && props.language === "ar"
                            ? "الفرق"
                            : "خاص"
              }
              list={specificData}
              value={filterForm.specific}
              listKey="id"
              description={props.language === "ar" ? "arabic_name" : "name"}
              customOnChange={customOnChange}
            />
          </Grid>
        </Grid>
        <Grid xs={12} md={12} style={{ marginTop: "20px" }}>
          <CustomDataTable
            data={data}
            columns={
              colName == "race_cols" || colName == "races_cols"
                ? columnsRaces
                : colName == "driver_cols" || colName == "drivers_cols"
                  ? columnsDrivers
                  : colName == "teams_cols" || colName == "team_cols"
                    ? columnsTeams
                    : columnsRaces
            }
            isLoading={isLoading}
            options={options}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Standings;
