import React, { useEffect, useState } from "react";
import { Drawer, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";
import { remove } from "../../features/basket/basketSlice";
import styles from "../../styles/Layout.module.css";
import { useTranslation } from "react-i18next";

const StoreDrawer = () => {
  let navigate = useNavigate();
  const items = useSelector((state) => state.basket.items);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [totalPrice, setTotalPrice] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    let total = 0;

    items.map(
      (item) =>
        (total += item.discounted_price
          ? item.discounted_price * item.buy
          : item.selling_price * item.buy)
    );

    setTotalPrice(total);
  }, [items]);

  return (
    <div>
      <div className={styles.shoppingHeader}>
        <ShoppingCartIcon
          onClick={() => setDrawerOpen((prev) => !prev)}
          sx={{ color: "white", fontSize: "30px" }}
        />
        <p>{items.length}</p>
      </div>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen((prev) => false)}
      >
        <div className={styles.drawer} style={i18n.language ==="ar" ?{direction:'rtl'} : null}>
          <div className={styles.drawerHeader}>
            <h3>{t('basket_word')}</h3>
            <h4 onClick={() => setDrawerOpen((prev) => false)}>x {t('close_word')}</h4>
          </div>
          <div className={styles.drawerBody}>
            {items.length == 0 ? (
              <h2> {t('no_products_word')}</h2>
            ) : (
              <div>
                <h3>{t('total_items_word')} {items.length}</h3>
                {items.map((item, index) => (
                  <Grid key={index} container spacing={4} p={2}>
                    <Grid item xs={6}>
                      <img
                        width={"100%"}
                        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/itemsImages/${item.image}`}
                      />
                    </Grid>
                    <Grid
                      p={2}
                      item
                      xs={6}
                      container
                      direction="column"
                      justifyContent="space-between"
                    >
                      <div>
                        <h3 style={{ margin: 0 }}>
                          { i18n.language ==="ar" ?item.arabic_name : item.name}
                        </h3>
                        <h3 style={{ margin: 0 }}>
                          $
                          {item.discounted_price
                            ? item.discounted_price
                            : item.selling_price}
                        </h3>
                      </div>
                      <div>
                        <h4 style={{ margin: 0 }}>{t('size_word')}: {item.size}</h4>
                        <h4 style={{ margin: 0 }}>{t('color_word')}: {item.colorName}</h4>
                        <h4 style={{ margin: 0 }}>
                          {t('total_word')}: $
                          {item.discounted_price
                            ? item.discounted_price * item.buy
                            : item.selling_price * item.buy}
                        </h4>
                      </div>
                      <div>
                        <h4>{t('quantity_word')}: {item.buy}</h4>
                        <button
                          className={styles.remove}
                          onClick={() => dispatch(remove(item))}
                        >
                          <DeleteIcon /> {t('remove_word')}
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </div>
            )}
          </div>

          <div className={styles.drawerFooter}>
            <h3>{t('subtotal_word')}</h3>
            <h4>${totalPrice}</h4>
          </div>
          <button
            onClick={() => navigate("/Checkout")}
            className={styles.checkout} style={{textTransform:"uppercase"}}
          >
            {t('checkout_word')}
          </button>
        </div>
      </Drawer>
    </div>
  );
};

export default StoreDrawer;
