import { Button, CircularProgress } from "@mui/material";
import React from "react";
import styles from "../../styles/new/ShareWithUs.module.css";
import attach from "../../assets/images/attach.png";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import handleErrors from "../../helpers/handleErrors";
import axios from "axios";
import { CustomDate } from "../../components/Inputs/CustomInputForms";
import * as moment from 'moment'
import { useTranslation } from "react-i18next";

const ShareTicket = () => {
  const [file, setFile] = useState([]);
  const { t, i18n } = useTranslation();
  const [viewedFile, setViewedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [races, setRaces] = useState([]);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    section: "",
    row: "",
    seat: "",
    type: "",
    price: "",
    date: Date.now(),
  });

  const getRaces = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/masterData/races/getByActiveSeason`,
        {}
      )
      .then(function (response) {
        setRaces(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const handleFile = (e) => {
    const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    console.log(file);
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFile({ id: randomId, file: file });
      setViewedFile({ id: randomId, path: reader.result });
    };
  };

  const removeImage = () => {
    setFile([]);
    setViewedFile([]);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setForm({
      ...form,
      [id]: value,
    });
  };

  useEffect(() => {
    getRaces();
  }, []);

  const share = () => {
    setLoading(true);
    const data = new FormData();

    data.append("ticket_number", Math.floor(Math.random() * 6));
    data.append("owner_name", form.name);
    data.append("owner_email", form.email);
    data.append("owner_phone", form.phone);
    data.append(
      "season_id",
      races.find((race) => race.id == form.section).season_id
    );
    data.append("race_id", form.section);
    data.append("seat", form.seat);
    data.append("row", form.row);
    data.append("type", form.type);
    data.append("price", form.price);
    // data.append("date", new Date().toLocaleString());
    data.append("date", moment(form.date).format('YYYY-MM-DD'));

    if (file) {
      console.log(file.file);
      data.append("file", file.file);
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/masterData/tickets/add`,
      data: data,
    })
      .then((response) => {
        setForm({
          name: "",
          email: "",
          phone: null,
          section: null,
          row: "",
          seat: "",
          type: "",
          price: "",
          date: Date.now(),
        });

        setViewedFile([]);
        setFile([]);
        setLoading(false);
        toast.success(`Shared successfully`);
      })
      .catch((error) => {
        setLoading(false);
        handleErrors(error);
      });
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  return (
    <>
      <div className={styles.topCard}>
        <div className={styles.header}>
          <h1>{t('sell_your_ticket')} </h1>
        </div>
        <div className={styles.body}>
          <h2 style={i18n.language ==="ar" ?{direction:'rtl'}:null}>{t('upload_qr_code_parag')}</h2>

          {viewedFile.id ? (
            <div style={{ position: "relative" }}>
              <img src={viewedFile.path} width="450" />
              <div style={{ position: "absolute", top: -5, right: -20 }}>
                <Button
                  color={"danger"}
                  size="sm"
                  className="m-2"
                  variant="outline"
                  onClick={removeImage}
                >
                  x
                </Button>
              </div>
            </div>
          ) : (
            <label className={styles.attachment}>
              <input
                onChange={handleFile}
                type="file"
                id="avatar"
                name="avatar"
                accept="image/png, image/jpeg"
              />
              <img alt="Image here" src={attach} />
            </label>
          )}

          <div className={styles.inputs} style={i18n.language ==="ar" ?{direction:'rtl'} : null}>
            <input
              type="text"
              id="name"
              value={form.name}
              onChange={handleInputChange}
              placeholder={t('your_name_here')}
            />
            <input
              type="email"
              id="email"
              value={form.email}
              onChange={handleInputChange}
              placeholder={t('your_email_here')}
            />
            <input
              type="number"
              id="phone"
              value={form.phone}
              onChange={handleInputChange}
              placeholder={t('your_phone_here')}
            />
          </div>
        </div>
      </div>

      <h1 className={styles.title}>{t('available')}</h1>
      <div className={styles.card}>
        <div className={styles.header}>
          {/* <CalendarMonthIcon
          style={{ fontSize: "35px", marginLeft: "28%", float: "left" }}
        /> */}
          <div> {t('fill_details')}</div>
        </div>
        <div className={styles.body} style={i18n.language ==="ar" ?{direction:'rtl'}:null}>
          <div className={styles.withBorder}>
            <p>{t('ticket_date')}</p>
            <CustomDate
              // label={'Ticket Date'}
              id={'date'}
              value={form.date}
              onChange={handleInputChange} />

          </div>
          <div className={styles.withBorder}>
            <p>{t('section_word')}</p>
            {/* <input
              type="text"
              id="section"
              value={form.section}
              onChange={handleInputChange}
              placeholder="Choose section"
            /> */}

            <select id="section" onChange={handleInputChange}>
              <option value="">{t('choose_section')}</option>
              {races.map((race) => (
                <option value={race.id} onSelect={(e) => console.log(e)}>
                  {race.name}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.withBorder}>
            <p>{t('row_word')}</p>
            <input
              type="text"
              id="row"
              value={form.row}
              onChange={handleInputChange}
              placeholder={t('enter_row')}
            />
          </div>
          <div className={styles.withBorder}>
            <p>{t('seat_word')}</p>
            <input
              type="text"
              id="seat"
              value={form.seat}
              onChange={handleInputChange}
              placeholder={t('enter_seat')}
            />
          </div>

          <div className={styles.withBorder}>
            <p>{t('type_word')}</p>
            <input
              type="text"
              id="type"
              value={form.type}
              onChange={handleInputChange}
              placeholder={t('enter_type')}
            />
          </div>

          <div className={styles.bottom}>
            <div
              className={styles.withBorder}
              style={{ width: "50%", float: "left" }}
            >
              <p>{t('price_word')}</p>
              <input
                type="number"
                id="price"
                value={form.price}
                onChange={handleInputChange}
                placeholder={t('enter_price')}
              />
            </div>
            <div style={{ textAlign: "center" }}>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <Button
                  variant="contained"
                  className={styles.bookButton}
                  onClick={share}
                >
                  {t('share_now')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareTicket;
