import React, { useState, useEffect, createRef } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import styles from '../styles/new/ContactUs.module.css'
import { Button, FormControl, TextField } from "@mui/material";
import { toast } from "react-toastify";
import handleErrors from "../helpers/handleErrors";

const ContactUs = () => {
    const { t, i18n } = useTranslation();
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [form, setForm] = useState({
        name: "",
        phone: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        const { id, value } = e.target;

        setForm((prev) => ({ ...prev, [id]: value }));
    };

    const sendMessage = () => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/contactUS/add`,
            data: form,
        })
            .then(response => {
                toast.success(`Message Sent`);
                setSaveIsLoading(false);
            })
            .catch(error => {
                setSaveIsLoading(false);
                handleErrors(error);
            });
    }
    return (
        <Grid container style={{ backgroundColor: '#F0F0F0' }}>
            <div className={styles.header}>
                <div className={styles.headerContent}>
                    <h3>{t('contact_us_word')}</h3>
                </div>
            </div>
            <Grid container
                className={styles.body}
                justifyContent={'center'}
                alignContent={"center"}
            >
                <div className={styles.cardSection}>
                    <div className={styles.cardSectionContact}>
                        <p>{t('get_in_touch')}</p>
                        <Grid container spacin={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl style={{ width: '100%' }}>
                                    <TextField
                                        style={{ margin: "20px" }}
                                        label={t('your_name_here')}
                                        variant="outlined"
                                        id="name"
                                        value={form.name}
                                        onChange={handleChange}
                                    /></FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl style={{ width: '100%' }}>
                                    <TextField
                                        style={{ margin: "20px" }}
                                        label={t('your_phone_here')}
                                        variant="outlined"
                                        id="phone"
                                        value={form.phone}
                                        onChange={handleChange}
                                    /></FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl style={{ width: '100%' }}>
                                    <TextField
                                        style={{ margin: "20px" }}
                                        label={t('your_email_here')}
                                        variant="outlined"
                                        id="email"
                                        value={form.email}
                                        onChange={handleChange}
                                    /></FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl style={{ width: '100%' }}>
                                    <TextField
                                        style={{ margin: "20px" }}
                                        label={t('message_word')}
                                        variant="outlined"
                                        id="message"
                                        multiline
                                        rows={6}
                                        value={form.message}
                                        onChange={handleChange}
                                    /></FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button variant="outlined" className={styles.submitBtn} onClick={sendMessage}>
                                    {t('submit_message')}
                                </Button>

                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <Grid container className={styles.body2}>

            </Grid>
        </Grid>
    );
}
export default ContactUs