import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/TicketCard.module.css";
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button, CardActionArea } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Book from "./Book";
import Receipt from "./Receipt";

const TicketCard2 = (props) => {
    const [data, setData] = useState(props.data);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    return (
        <>
            <div className={styles.card}>
                <div className={styles.header}>
                    <CalendarMonthIcon style={{ fontSize: '35px', marginLeft: '28%', float: 'left' }} />
                    <div>{data.date}</div>
                </div>
                <div className={styles.body}>
                    <div className={styles.withBorder}>
                        <p>{t('section_word')}</p>
                        {
                            i18n.language ==="ar"
                            ?
                            <span>{data.arabic_race_name}</span>
                            :
                            <span>{data.race_name}</span>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default TicketCard2;
