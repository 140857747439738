import React from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo-white.png";
import Grid from "@mui/material/Grid";

import youtube from "../../assets/images/youtube-icon.png";
import insta from "../../assets/images/insta-icon.png";
import twitter from "../../assets/images/twitter-icon.png";

const Footer = () => {
  const location = useLocation();

  return (
    // location.pathname == "/NewsArticles" && (
    <div>
      <footer className="footer">
        <Grid container>
          <Grid item xs={12} md={3} style={{ textAlign: 'center', padding: '30px 30px' }}>
            <img src={logo} height={'120px'} style={{ paddingTop: '10px' }} />
          </Grid>
          <Grid item xs={6} md={3}
          //  className="footer-part2"
           >
            <Grid container>
              <Grid item xs={5} md={4}>
                <div className="footer-content">
                  <ul>
                    <li>
                      <h4 style={{
                        fontSize:'18px',
                        color: '#FFFFFF',
                        textTransform:'uppercase',
                        fontWeight:'800',
                        fontFamily:'League Spartan',
                      }}>LINKS</h4>
                    </li>
                    <li>
                      <a href="#" >Home</a>
                    </li>
                    <li>
                      <a href="#" >About</a>
                    </li>
                    <li>
                      <a href="#" >F1</a>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={6} md={4} style={{ marginLeft: '-30px' }}>
                <div className="footer-content">
                  <ul>
                    <li>
                      <h4 style={{ color: "#BF2A30" }}>.</h4>
                    </li>
                    <li>
                      <a href="#" style={{ textAlign: 'left' }}>MENA TV</a>
                    </li>
                    <li>
                      <a href="#" style={{ textAlign: 'left' }}>NEWS</a>
                    </li>
                    <li>
                      <a href="#" style={{ textAlign: 'left' }}>STORE</a>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} md={2}>
            <div style={{ marginLeft: '-50px' }} classsName="footer-content">
              <ul>
                <li>
                  <h4 style={{
                        fontSize:'18px',
                        color: '#FFFFFF',
                        textTransform:'uppercase',
                        fontWeight:'800',
                        fontFamily:'League Spartan',
                      }}>Follow us</h4>
                </li>

                <li>
                  <a href="#">
                    <img src={twitter} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={insta} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={youtube} />
                  </a>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="footer-content">
              <ul>
                <li>
                  <h4 style={{
                        fontSize:'18px',
                        color: '#FFFFFF',
                        textTransform:'uppercase',
                        fontWeight:'800',
                        fontFamily:'League Spartan',
                      }}>Subscribe to receive all new </h4>
                </li>
                <li>
                  <input />
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </footer>
      <div className="fotter-bottom">
        <h1>© 2022 Mena Grand Prix. All rights reserved.</h1>
      </div>
    </div>
    // )
  );
};

export default Footer;
