import React, { useState, useEffect, createRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Grid from "@mui/material/Grid";
import styles from "../../styles/DriverCardDetails.module.css";
// import video from "../../assets/videos/racing.mp4";
import { generateArrayOfYears } from "../../helpers/FixedData";
import ImageShow from "../../components/NewsArticles/ImageShow.js";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProductsSlider from "../../components/Store/ProductsSlider";

const DriverCardDetails = (props) => {
  const { season_id } = useParams();
  const { id } = useParams();
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const videoRef = createRef();
  const [width, setWidth] = useState(100);
  const [filterForm, setFilterForm] = useState({
    driver_id: id,
  });

  useEffect(() => {
    const getDrivers = () => {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/masterData/drivers/getBySeasonDetails`,
          {
            params: {
              season_id: season_id,
              id: id,
            },
          }
        )
        .then(function (response) {
          setData(response.data);
        })
        .catch(function (error) {
          console.log(error.response);
        });
    };
    getDrivers();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "110vh" }}
      >
        <div className={styles.container}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              style={{ borderBottom: "1px solid #EAECEE" }}
            >
              <img
                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/driversImages/${data.profile_wallpaper}`}
                width={"100%"}
                height={"450px"}
                loading={"lazy"}
                style={{ objectFit: "cover", objectPosition: "20% 10%" }}
              />
              <div className={styles.subImage}>
                <span>{data.driver_number}</span>
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/countriesImages/${data.country_image}`}
                  height={"35"}
                  width={"45"}
                  style={{ borderRadius: "10px" }}
                  loading={"lazy"}
                />
                <p>
                  {
                    props.language === "ar" ?
                      <>
                        {data.arabic_first_name} {data.arabic_last_name}
                      </>
                      :
                      <>
                        {data.first_name} {data.last_name}
                      </>
                  }
                </p>
              </div>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              style={{ borderBottom: "1px solid #EAECEE" }}
            >
              <table className={styles.tableRight} style={props.language === "ar" ? { direction: "rtl", textAlign: "right" } : null}>
                <tr>
                  <th width="50%" align={props.language === "ar" ? 'right' : 'left'}>
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/driversImages/${data.helmet_image}`}
                      width={"190"}
                      height={"140"}
                      loading={"lazy"}
                    />
                  </th>
                  <th align={props.language === "ar" ? 'center' : null} style={{ color: "red", fontSize: "35px" }}>{t('official_word')}</th>
                </tr>
                <tr>
                  <th width="50%" align={props.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                    {t('team_word')}
                  </th>
                  <th width="50%" align="center" className={styles.rightTH}>
                    {
                      props.language === "ar" ?
                        data.arabic_team_name
                        :
                        data.team_name
                    }
                  </th>
                </tr>
                <tr>
                  <th width="50%" align={props.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                    {t('country_word')}
                  </th>
                  <th width="50%" align="center" className={styles.rightTH}>
                    {
                      props.language === "ar" ?
                        data.arabic_country_name
                        :
                        data.country_name
                    }
                  </th>
                </tr>
                <tr>
                  <th width="50%" align={props.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                    {t('podium_word')}
                  </th>
                  <th width="50%" align="center" className={styles.rightTH}>
                    {
                      data.podium
                    }
                  </th>
                </tr>
                <tr>
                  <th width="50%" align={props.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                    {t('points_word')}
                  </th>
                  <th width="50%" align="center" className={styles.rightTH}>
                    {
                      data.total_points
                    }
                  </th>
                </tr>
                <tr>
                  <th width="50%" align={props.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                    {t('grand_prix_word')}
                  </th>
                  <th width="50%" align="center" className={styles.rightTH}>
                    {
                      data.grand_prix_entered
                    }
                  </th>
                </tr>
                <tr>
                  <th width="50%" align={props.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                    {t('championship_word')}
                  </th>
                  <th width="50%" align="center" className={styles.rightTH}>
                    {
                      data.world_championships
                    }
                  </th>
                </tr>
                <tr>
                  <th width="50%" align={props.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                    {t('highest_race_word')}
                  </th>
                  <th width="50%" align="center" className={styles.rightTH}>
                    {
                      data.highest_race_finish
                    }
                  </th>
                </tr>
                <tr>
                  <th width="50%" align={props.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                    {t('highestgrid_word')}
                  </th>
                  <th width="50%" align="center" className={styles.rightTH}>
                    {
                      data.highest_grid_position
                    }
                  </th>
                </tr>
                <tr>
                  <th width="50%" align={props.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                    {t('date_of_birth_word')}
                  </th>
                  <th width="50%" align="center" className={styles.rightTH}>
                    {data.date_of_birth}
                  </th>
                </tr>
                <tr>
                  <th width="50%" align={props.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                    {t('place_of_birth_word')}
                  </th>
                  <th width="50%" align="center" className={styles.rightTH}>
                    {data.place_of_birth}
                  </th>
                </tr>
              </table>
            </Grid>
            <Grid item xs={12} md={12}>
              <div className={styles.containerPart2}>
                <h2>{t('biography')}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      props.language === "ar" ?
                        data.arabic_description
                        :
                        data.description
                  }}
                ></div>
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <ImageShow data={data} path={"driversImages"} />
            </Grid>
            <Grid item xs={12} md={12}>
              <ProductsSlider
                filterForm={filterForm}
                title={t('shop_now')}
                slidesToShowMobile={2}
                slidesToShow={3}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default DriverCardDetails;
