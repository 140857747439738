import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/TeamsDetails.module.css";
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import axios from "axios";
import { CircularProgress } from "@mui/material";

const Details = (props) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const getData = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/masterData/teams/getBySeasonDetails`,
                {
                    params: {
                        season_id: props.season_id,
                        id: props.id,
                    },
                }
            )
            .then(function (response) {
                setData(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error.response);
                setLoading(false);
            });
    }
    useEffect(() => {
        if (props.id > 0 && props.open == true) {
            getData();
        }
    }, [props.open])

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                maxWidth='lg'
                fullWidth
            >
                <div style={{ width: '100%' }}>
                    {
                        loading ? <CircularProgress />
                            :
                            <>
                                <Grid container>
                                    <Grid item xs={12} md={4}>
                                        <div className={styles.detailsHeaderImg}>
                                            <img
                                                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/driversImages/${data.driver1_image}`}
                                                // src={`https://pbs.twimg.com/media/FcTXwpsX0AQcqQl.jpg:large`}
                                                height={"250"}
                                                width={"400"}
                                                loading={"lazy"}
                                                style={{objectFit:'scale-down'}}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <div className={styles.detailsHeaderRight1}>
                                            <div className={styles.detailsFlag}>
                                                <img
                                                    src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/countriesImages/${data.driver1_country_image}`}
                                                    // src={`https://www.flagdetective.com/images/download/lebanon-hi.jpg`}
                                                    height={"45"}
                                                    width={"60"}
                                                    style={{ border: '1px solid black', objectFit: 'cover' }}
                                                    loading={"lazy"}
                                                />
                                            </div>
                                            <div className={styles.detailsHeaderRight1Details}>
                                                <div className={styles.detailsHeaderRight1Driver}>
                                                    <p>
                                                        {
                                                            i18n.language === "ar" ?
                                                                <>
                                                                    {data.driver1_firstname_arabic} {data.driver1_lastname_arabic}
                                                                </>
                                                                :
                                                                <>
                                                                    {data.driver1_firstname} {data.driver1_lastname}
                                                                </>
                                                        }
                                                    </p>
                                                </div>
                                                <div className={styles.detailsHeaderRight1Points}>
                                                    <p style={i18n.language === "ar" ? { direction: "rtl"} : null}>{t('driver_points')} {data.driver1_points ?data.driver1_points : 0}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ marginTop: '10px' }}>
                                        <div className={styles.detailsHeaderImg}>
                                            <img
                                                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/driversImages/${data.driver2_image}`}
                                                // src={`https://pbs.twimg.com/media/FcTXwpsX0AQcqQl.jpg:large`}
                                                height={"250"}
                                                width={"400"}
                                                loading={"lazy"}
                                                style={{objectFit:'scale-down'}}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={8} style={{ marginTop: '10px' }}>
                                        <div className={styles.detailsHeaderRight1}>
                                            <div className={styles.detailsFlag}>
                                                <img
                                                    src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/countriesImages/${data.driver2_country_image}`}
                                                    // src={`https://www.flagdetective.com/images/download/lebanon-hi.jpg`}
                                                    height={"45"}
                                                    width={"60"}
                                                    style={{ border: '1px solid black', objectFit: 'cover' }}
                                                    loading={"lazy"}
                                                />
                                            </div>
                                            <div className={styles.detailsHeaderRight1Details}>
                                                <div className={styles.detailsHeaderRight1Driver}>
                                                    <p>{
                                                        i18n.language === "ar" ?
                                                            <>
                                                                {data.driver2_firstname_arabic} {data.driver2_lastname_arabic}
                                                            </>
                                                            :
                                                            <>
                                                                {data.driver2_firstname} {data.driver2_lastname}
                                                            </>
                                                    }</p>
                                                </div>
                                                <div className={styles.detailsHeaderRight1Points}>
                                                    <p style={i18n.language === "ar" ? { direction: "rtl"} : null}>{t('driver_points')} {data.driver2_points ?data.driver2_points : 0}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <div className={styles.detailsHeaderRight2}>
                                            <div className={styles.detailsHeaderRight2Content}>
                                                <table className={styles.tableRight} style={i18n.language === "ar" ? { direction: "rtl", textAlign: "right" } : null}>
                                                    <tbody>
                                                        <tr>
                                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                                {t('team_word')}
                                                            </th>
                                                            <th width="50%" align="right" className={styles.rightTH}>
                                                                {
                                                                    i18n.language === "ar" ?
                                                                        data.data.arabic_name
                                                                        :
                                                                        data.data.name
                                                                }
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                                {t('base_word')}
                                                            </th>
                                                            <th width="50%" align="right" className={styles.rightTH}>
                                                                {data.data.base}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                                {t('chief_word')}
                                                            </th>
                                                            <th width="50%" align="right" className={styles.rightTH}>
                                                                {data.data.team_chief}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                                {t('tech_chief_word')}
                                                            </th>
                                                            <th width="50%" align="right" className={styles.rightTH}>
                                                                {data.data.technical_chief}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                                {t('chassis_word')}
                                                            </th>
                                                            <th width="50%" align="right" className={styles.rightTH}>
                                                                {data.data.chassis}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                                {t('power_unit_word')}
                                                            </th>
                                                            <th width="50%" align="right" className={styles.rightTH}>
                                                                {data.data.power_unit}

                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                                {t('first_team_entry_word')}
                                                            </th>
                                                            <th width="50%" align="right" className={styles.rightTH}>
                                                                {data.data.first_team_entry}

                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                                {t('world_champion_word')}
                                                            </th>
                                                            <th width="50%" align="right" className={styles.rightTH}>
                                                                {data.data.world_champ}

                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                                {t('highest_race_word')}
                                                            </th>
                                                            <th width="50%" align="right" className={styles.rightTH}>
                                                                {data.data.highest_race_finish}

                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                                {t('pole_position_word')}
                                                            </th>
                                                            <th width="50%" align="right" className={styles.rightTH}>
                                                                {data.data.pole_positions}

                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                                {t('pole_position_word')}
                                                            </th>
                                                            <th width="50%" align="right" className={styles.rightTH}>
                                                                {data.data.fastest_laps}

                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </Grid>
                                </Grid>
                                <div>
                                    <div className={styles.biographyTitle} style={i18n.language ==="ar" ? {textAlign:'right',direction:'rtl'} : null}>
                                        <div className={styles.biographyContent} >
                                            {t('in_profile_word')}
                                        </div>
                                        <div className={styles.biographyBody}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        i18n.language === "ar" ?
                                                            data.data.arabic_description
                                                            :
                                                            data.data.description
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }

                </div>
            </Dialog >
        </div >
    );
};

export default Details;
