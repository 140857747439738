import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Hero from "../components/NewsArticles/Hero";
import SignUp from "../components/NewsArticles/SignUp";
import News from "../components/NewsArticles/News";
import Store from "../components/NewsArticles/Store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Feedback from "../components/NewsArticles/Feedback";
import axios from "axios";
import Grid from '@mui/material/Grid';
import styles from "../styles/new/NewsArticels.module.css";
import headerBackground from '../assets/images/full-flag.jpeg'
import Header2 from "../components/Layout/Header2";
import ADS from "../components/NewsArticles/ADS";
import NewsCard from "../components/NewsArticles/NewsCard";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NewsArticles = (props) => {
  const [category, setCategory] = useState(
    window?.location?.pathname?.split("news-articles/")[1] || 1
  );
  const { category_id } = useParams();
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);

  const [news, setNews] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState({});
  const [subcategories, setSubcategories] = useState([]);
  const getNews = (arr, page) => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/news/get`, {
        params: {
          category_id: category_id,
          offset: page,
        },
      })
      .then(function (response) {
        // setSubcategories(response.data.subcategories);
        // setCategoryDetails(response.data.category);
        // setNews(response.data.news);
        let tempData = [...arr];
        let dataNews = response.data.news;
        if (dataNews.length > 0) {
          for (var i = 0; i < dataNews.length; i++) {
            tempData.push(dataNews[i]);
          }
        }
        setNews(tempData);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    let data = [];
    if (category) getNews(data, 0);
  }, [category]);

  useEffect(() => {
    let data = news;
    getNews(data, page);
  }, [page]);

  const loadMore = () => {
    setPage(page + 1);
  };


  return (
    <>
      <Grid container style={{ backgroundColor: '#F0F0F0' }}>
        <Grid item md={12}>
          <div className={styles.header} style={{ backgroundImage: `url(${headerBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
            <p>FORMULA 1 </p>
          </div>
        </Grid>
        {
          category_id > 0 ?
            <Grid item xs={12} md={12} style={{ backgroundColor: '#F0F0F0' }}>
              <div className={styles.subHeaderMenu}>
                <Header2 />
              </div>
            </Grid>
            :
            null
        }

        <ADS />
        <div className={styles.subHeaderLine}>
          <div className={styles.subHeaderLineContent}>
          </div>
        </div>
        {
          news.map((e, index) => (
            <>
              <NewsCard data={e} />
            </>
          ))
        }


        <div className={styles.viewsPar}>
          <div className={styles.viewsContainer}>
            <p style={i18n.language === "ar" ? { direction: "rtl", textAlign: "center" } : null}>{t('You_ve_viewed')} {news.length}  {t('from_news_word')}</p>
          </div>
        </div>

        <div className={styles.loadingBtn}>
          <div className={styles.loadingBtnContainer} onClick={() => { loadMore() }}>
            <p>{t('load_more')}</p>
          </div>
        </div>
      </Grid>
      {/* <Hero imgSrc={categoryDetails?.image} language={props.language} />
      {subcategories.map((subcategory, index) => (
        <div key={index}>
          <News
            category={category}
            news={news.filter((data) => data.subcategory_id == subcategory.id)}
            language={props.language}
          />
        </div>
      ))}

      <Store language={props.language} /> */}
    </>
  );
};

export default NewsArticles;
