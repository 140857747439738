import React, { useState, useEffect, createRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ADS from "../../components/NewsArticles/ADS";
import styles from "../../styles/new/Store.module.css";
import TeamCard from "../Teams/TeamCard";
import { generateArrayOfYears } from "../../helpers/FixedData";
import SingleCustomAutocomplete from "../../components/CustomAutocomplete/SingleCustomAutocomplete";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import headerBackground from '../../assets/images/teamHeader.png'
import Header2 from "../../components/Layout/Header2";
import ItemCard from "./ItemCard";
import { Link } from "react-router-dom";

const NewStore = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState({});
    const [seasons, setSeasons] = useState([]);
    const [data, setData] = useState([]);

    const [id, setId] = useState('');
    const [visible, setVisible] = useState(false);

    const [drivers, setDrivers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [filterForm, setFilterForm] = useState({
        driver_id: null,
        team_id: null,
    });
    useEffect(() => {
        const getDrivers = () => {
            axios
                .get(`${process.env.REACT_APP_API_BASE_URL}/masterData/drivers/get`, {
                    params: {},
                })
                .then(function (response) {
                    setDrivers((prev) => response.data);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        };

        const getTeams = () => {
            axios
                .get(`${process.env.REACT_APP_API_BASE_URL}/masterData/teams/get`, {
                    params: {},
                })
                .then(function (response) {
                    setTeams((prev) => response.data);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        };


        getDrivers();
        getTeams();
    }, []);
    const customOnChange = (newValue, name) => {
        setFilterForm((prev) => ({ ...prev, [name]: newValue }));
    };
    const getPageData = () => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
                params: {
                    name: "Team",
                },
            })
            .then(function (response) {
                setPageData((prev) => response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error.response);
                setLoading(false);
            });
    };
    useEffect(() => {
        getPageData();
    }, []);

    const getData = () => {
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/data/items/get`, {
                params: {
                    name: "Store",
                    driver_id: filterForm.driver_id,
                    team_id: filterForm.team_id,
                },
            })
            .then(function (response) {
                let data = response.data;
                setData(data);
            })
            .catch((error) => console.log(error.response));
    };


    useEffect(() => {
        getData();
    }, [filterForm.driver_id, filterForm.team_id]);

    return (
        <>
            <Grid container style={{ backgroundColor: '#F0F0F0' }}>
                <div className={styles.header} style={{ backgroundImage: `url(${headerBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                    <div className={styles.headerContent}>
                        <div>
                            <h3>{t('shop_now')}</h3>
                        </div>
                    </div>
                </div>

                <ADS />

                <div className={styles.subHeaderFilter}>
                    <Grid container spacing={2} >
                        <Grid item md={2} xs={0}></Grid>
                        <Grid item md={4} xs={12} style={{ marginTop: "2rem" }}>
                            <div style={props.language === "ar" ? { direction: "rtl", textAlign: "right" } : null} >
                                <p style={props.language === "ar" ? { float: 'left', textAlign: "left" } : null}>
                                    <SingleCustomAutocomplete
                                        filedName="driver_id"
                                        label={t('drivers')}
                                        list={drivers}
                                        value={filterForm.driver_id}
                                        listKey="id"
                                        description={i18n.language === "ar" ? "arabic_full_name" : "name"}
                                        customOnChange={customOnChange}
                                    />
                                </p>
                            </div>
                        </Grid>
                        <Grid item md={4} xs={12} style={{ marginTop: "2rem" }}>
                            <div style={props.language === "ar" ? { direction: "rtl", textAlign: "right" } : null}>
                                <p style={props.language === "ar" ? { float: 'left', textAlign: "left" } : null}>
                                    <SingleCustomAutocomplete
                                        filedName="team_id"
                                        label={t('teams')}
                                        list={teams}
                                        value={filterForm.team_id}
                                        listKey="id"
                                        description={i18n.language === "ar" ? "arabic_name" : "name"}
                                        customOnChange={customOnChange}
                                    />
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className={styles.subHeaderLine}>
                    <div className={styles.subHeaderLineContent}>
                    </div>
                </div>
                <div className={styles.CardsSection}>
                    <Grid container spacing={10}>
                        {
                            data.map((e, index) => (
                                <>
                                    <Grid item xs={12} md={4} key={index}>
                                        <Link to={`/store-items/${e.id}`} style={{textDecoration:'none'}}>
                                            <div><ItemCard data={e} /></div>
                                        </Link>
                                    </Grid>
                                </>
                            ))
                        }
                    </Grid>
                </div>
            </Grid>
        </>
    );
};

export default NewStore;
