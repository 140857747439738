import React, { useState, useEffect } from "react";
import styles from "../../styles/new/DemoReceipt.module.css";
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import TicketCard from "./TicketCard";
import TicketCard2 from "./TicketCard2";
import { Button, Grid } from "@mui/material";

const Receipt = (props) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState(props.data);

    useEffect(() => {
        console.log("test" + props.open);
        console.log(props.data);
    }, []);
    const downloadQR = () => {
        // const image =  fetch(`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/ticketsImages/${data.image}`);
        // const imageBlog =  image.blob();
        // const imageURL = URL.createObjectURL(imageBlog);


        var element = document.createElement("a");
        var file = new Blob(
            [
                `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/ticketsImages/${data.image}`
            ]
        );
        element.href = URL.createObjectURL(file);
        // element.href = imageURL;
        element.download = data.image;
        // document.body.appendChild(element);
        element.click();
        // document.body.removeChild(element);
        // URL.revokeObjectURL(element.href);
    }
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                maxWidth='md'
                fullWidth
            >
                <div style={{ width: '100%', textAlign: 'center', paddingTop: '10px', backgroundColor: '#F0F0F0', height: '500px' }}>
                    <div className={styles.body} style={{ marginTop: '50px' }}>
                        <p>Your Ticket</p>
                    </div>
                    {/* <div className={styles.body2}>
                        <p>Section</p>
                        <div>{data.race_name}</div>
                    </div>
                    <div className={styles.body2}>
                        <p>Date</p>
                        <div>{data.date}</div>
                    </div> */}
                    <div style={{ marginBottom: '20px', marginTop: '50px', padding: '5px' }}>
                        <Grid container justifyContent={"center"} textAlign={'center'}>
                            <Grid item xs={12} md={6}>
                                <TicketCard2 data={data} />
                            </Grid>
                        </Grid>
                    </div>
                    {/* <div style={{ textAlign: 'center' }}>
                        <a href={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/ticketsImages/${data.image}`}  download target="_blank">
                            <Button variant="contained"
                                style={{
                                    backgroundColor: '#544F50'
                                    , textTransform: 'uppercase'
                                    , marginTop: '20px'
                                    , height: '60px'
                                    , width: '150px'
                                }}

                            >
                                {t('print_qr')}
                            </Button>
                        </a>
                    </div> */}

                </div>
            </Dialog>
        </div>
    );
};

export default Receipt;
