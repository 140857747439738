import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/ItemCard.module.css";
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

const ItemCard = (props) => {
    const { t, i18n } = useTranslation();
    const item = props.data;

    return (
        <>
            <Card sx={{ maxWidth: 500 }} >
                <CardActionArea>
                    {/* <CardMedia
                        component="img"
                        height="300"
                        image={'https://s3-eu-west-1.amazonaws.com/racingnews-v2-prod/Riders/Leclerc/_570x570_crop_center-center_none/f1_2022_cl_fer_lg.png?v=1647865421'}
                        alt="driver"
                        style={{ objectFit: 'scale-down' }}
                    />
                    <CardContent style={{ backgroundColor: '#544F50', height: '140px' }} >

                        <div className={styles.driverCardDriverName}><p>
                            Charles Leclerc
                        </p></div>
                    </CardContent> */}
                    <div className={styles.imageSection}>
                        <img
                            src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/itemsImages/${item.image}`}
                            // src={`https://s3-eu-west-1.amazonaws.com/racingnews-v2-prod/Riders/Leclerc/_570x570_crop_center-center_none/f1_2022_cl_fer_lg.png?v=1647865421`}
                            style={{ objectFit: 'scale-down' }}
                            height={'300'}
                            loading={"lazy"}
                        />
                    </div>
                    <div className={styles.bodySection}>
                        <div style={i18n.language === "ar" ? { direction: "rtl",marginRight:'20px'} : null}>
                            {
                                i18n.language == "ar" ? item.arabic_name : item.name
                            }
                        </div>
                        <div style={i18n.language === "ar" ? { direction: "rtl",marginRight:'20px'} : null}>
                            <span style={{textDecoration:'line-through'}}>{item.discounted_price ? "$" + item.discounted_price+" ": ""}</span>
                            <span>${item.selling_price}</span>
                        </div>
                    </div>
                    <div className={styles.buttomSection}>
                        <div>
                            View Item
                        </div>
                    </div>
                </CardActionArea>
            </Card>
        </>
    );
};

export default ItemCard;
