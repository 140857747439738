import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/Drivers.module.css";
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import axios from "axios";
import { CircularProgress } from "@mui/material";

const WatchVideo = (props) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getVideo = () => {
            setLoading(true);
            axios
                .get(
                    `${process.env.REACT_APP_API_BASE_URL}/data/videos/getVideoById/${props.id}`,
                    {}
                )
                .then(function (response) {
                    setData(response.data);
                    setLoading(false);
                })
                .catch(function (error) {
                    console.log(error.response);
                    setLoading(false);
                });
        };
        if (props.open) {
            getVideo();
        }
    }, [props.open]);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                maxWidth='md'
                fullWidth
            >
                <div style={{ width: '100%' }}>
                    {
                        loading ? <CircularProgress />
                            :
                            <div style={{ position: 'relative', width: 'fit-content',width:'100%'}}>
                                <video width={'100%'} height="400" controls>
                                    <source src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/menaTvVideos/${data.video}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                    }
                </div>
            </Dialog>
        </div>
    );
};

export default WatchVideo;
