import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
import axios from "axios";

import Drawer from "./StoreDrawer";
import { Close } from "@mui/icons-material";
import styles from "../../styles/Layout.module.css";
const Header = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [categoryID, setCategoryID] = useState("");

  const [logoSource, setLogoSource] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const mobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const category_id = localStorage.getItem("category_id");
    if (category_id) {
      setCategoryID(category_id);
    }
  }, []);

  const getPageData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "Header",
        },
      })
      .then(function (response) {
        const src = `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image1}`;
        setLogoSource((prev) => src);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getPageData();
    console.log("category" + localStorage.getItem("category_id"));
  }, []);

  useEffect(() => {
    console.log(location);
    setMenuOpen(false);
  }, [location]);

  return (
    <>
      {location.pathname.toLowerCase().includes("store") ? <Drawer /> : null}

      {mobile ? (
        <div className={styles.navMenu}>
          <div className={menuOpen ? styles.menuLinks : styles.hidenMenuLinks}>
            <NavLink
              style={{ margin: "auto", marginBottom: "20px" }}
              to="/Home"
              onClick={() => {
                localStorage.removeItem("category_id");
                localStorage.removeItem("category_name");
              }}
            >
              <img alt="MENA" height={"50px"} src={logoSource} />
            </NavLink>
            <NavLink
              to="/Home"
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>{t("home")}</h1>
            </NavLink>

            <NavLink
              to="/news-articles/1"
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>F1</h1>
            </NavLink>

            <NavLink
              to="/MenaTv"
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>Mena Tv</h1>
            </NavLink>
            <NavLink
              to="/news"
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>{t('news_articles')}</h1>
            </NavLink>

            {/* <NavLink
              to={{
                pathname:
                  "/" + localStorage.getItem("category_id") + "/Drivers",
              }}
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>{t("drivers")}</h1>
            </NavLink>
            <NavLink
              to={{
                pathname: "/" + localStorage.getItem("category_id") + "/Teams",
              }}
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>{t("teams")}</h1>
            </NavLink>

            <NavLink
              to={{
                pathname:
                  "/" + localStorage.getItem("category_id") + "/Schedules",
              }}
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>{t("schedules")}</h1>
            </NavLink>

            <NavLink
              to={{
                pathname:
                  "/" + localStorage.getItem("category_id") + "/Standings",
              }}
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>{t("standings")}</h1>
            </NavLink> */}

            <NavLink
              to="/Store"
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>{t("store")}</h1>
            </NavLink>


            <NavLink
              to="/About-us"
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>ABOUT US</h1>
            </NavLink>

            <NavLink
              to="/Tickets"
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>TICKETS</h1>
            </NavLink>

            <NavLink
              to="/ShareWithUs"
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>{t('share_with_us')}</h1>
            </NavLink>


            <NavLink
              to="/Contact-us"
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>CONTACT US</h1>
            </NavLink>

            <div
              className={styles.languagePart}
              style={{
                borderRight: "2px solid white",
                borderLeft: "2px solid white",
                padding: "10px",
              }}
            >
              {i18n.language === "ar" ? (
                <>
                  <div
                    style={{ color: "white", top: "25%", position: "relative" }}
                  >
                    <Flag code="gb" height="13" style={{ margin: "1px" }} />
                    <span
                      role="button"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        i18n.changeLanguage("en");
                        props.handleChangeLanguage("en");
                      }}
                    >
                      EN
                    </span>
                  </div>
                </>
              ) : (
                <div
                  style={{ color: "white", top: "25%", position: "relative" }}
                >
                  <Flag code="sa" height="13" style={{ margin: "1px" }} />
                  <span
                    role="button"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      i18n.changeLanguage("ar");
                      props.handleChangeLanguage("ar");
                    }}
                  >
                    AR
                  </span>
                </div>
              )}
            </div>
          </div>

          <a
            href="javascript:void(0);"
            className={styles.menuBtn}
            onClick={() => setMenuOpen((prev) => !prev)}
          >
            {menuOpen ? (
              <Close className={styles.menuIcon} />
            ) : (
              <MenuIcon className={styles.menuIcon} />
            )}
          </a>
        </div>
      ) : (
        <>
          <div className={i18n.language === "ar" ? styles.headerNavArabic : styles.headerNav}>
            <div
              className={styles.bar}
              style={
                props.language === "ar"
                  ? { direction: "rtl", textAlign: "right" }
                  : null
              }
            >
              <nav className={styles.nav}>
                <NavLink
                  to="/Home"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                  onClick={() => {
                    localStorage.removeItem("category_id");
                    localStorage.removeItem("category_name");
                  }}
                >
                  <h1 className={styles.navItem}>{t("home")}</h1>
                </NavLink>

                <NavLink
                  to="/About-us"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>{t("about_us")}</h1>
                </NavLink>

                <NavLink
                  to="/news-articles/1"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>F1</h1>
                </NavLink>

                <NavLink
                  to="/MenaTv"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>MENA TV</h1>
                </NavLink>
                <NavLink
                  to="/news"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>{t('news_articles')}</h1>
                </NavLink>

                {/* <NavLink
                  to="/Schedules"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>{t("schedules")}</h1>
                </NavLink> */}

                <Link to="/Home">
                  <img alt="MENA" height={"100px"} src={logoSource} />
                </Link>
                {/* 
                <NavLink
                  to="/Drivers"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>{t("drivers")}</h1>
                </NavLink>
                <NavLink
                  to="/Teams"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>{t("teams")}</h1>
                </NavLink>

                <NavLink
                  to="/Standings"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>{t("standings")}</h1>
                </NavLink> */}

                <NavLink
                  to="/Store"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>{t("store")}</h1>
                </NavLink>


                <NavLink
                  to="/Tickets"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>{t('tickets_word')}</h1>
                </NavLink>

                <NavLink
                  to="/ShareWithUs"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>{t('share_with_us')}</h1>
                </NavLink>

                <NavLink
                  to="/Contact-us"
                  className={({ isActive }) =>
                    isActive ? styles.active_link : styles.link
                  }
                >
                  <h1 className={styles.navItem}>{t('contact_us_word')}</h1>
                </NavLink>
                <div className={styles.languagePart}>
                  {i18n.language === "ar" ? (
                    <>
                      <div
                        style={
                          {
                            // color: "white",
                            // top: "25%",
                            // position: "relative",
                          }
                        }
                      >
                        <Flag code="gb" height="13" style={{ margin: "1px" }} />
                        <span
                          role="button"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            i18n.changeLanguage("en");
                            props.handleChangeLanguage("en");
                          }}
                        >
                          EN
                        </span>
                      </div>
                    </>
                  ) : (
                    <div
                      style={
                        {
                          // color: "white",
                          // top: "25%",
                          // position: "relative",
                        }
                      }
                    >
                      <Flag code="sa" height="13" style={{ margin: "1px" }} />
                      <span
                        role="button"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          i18n.changeLanguage("ar");
                          props.handleChangeLanguage("ar");
                        }}
                      >
                        AR
                      </span>
                    </div>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
