import React, { useState, useEffect, createRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/SchedulesDetails.module.css";
import { useParams } from 'react-router-dom';
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import headerBackground from '../../assets/images/schedulesImg.png'
import SmallHeader from "../../components/Layout/SmallHeader";

const ScheduleCircuit = (props) => {
    const { season_id } = useParams();
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(true);
    const [data, setData] = useState([]);
    const [races, setRaces] = useState([]);

    useEffect(() => {
        const getData = () => {
            setShow(true);
            axios
                .get(`${process.env.REACT_APP_API_BASE_URL}/data/races/getSchedulesDetails`, {
                    params: {
                        season_id: season_id,
                        id: id
                    }
                })
                .then(function (response) {
                    setData(response.data[0]);
                    setRaces(response.data['details']);
                    // setStart(response.data['start_day']);
                    // setEnd(response.data['end_day']);
                    // setMOnth(response.data['month']);
                    setShow(false);

                })
                .catch(function (error) {
                    console.log(error.response);
                    setShow(false);
                });
        };
        getData();
    }, []);


    return (
        <>
            {
                show ? <CircularProgress />
                    :
                    <Grid container style={{ backgroundColor: '#F0F0F0' }}>
                        <div className={styles.header} style={{ backgroundImage: `url(${headerBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                        </div>
                        <Grid item md={12} xs={12}>
                            <div className={styles.subHeaderMenu}>
                                <SmallHeader season_id={season_id} id={id} />
                            </div>
                        </Grid>
                        <div className={styles.circuitBody}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <div style={{ width: '100%', border: '2px solid #BF2A30' }}>
                                        {/* <div style={{
                                            // backgroundImage: `url(http://cdn.shopify.com/s/files/1/1078/9158/products/Circuit-de-Cartagena-Spain-360mm-and-580mm-Wooden-Wall-Art-Track-Sculpture-Layout.jpg?v=1497094536)`,
                                            backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/trackImages/${data.detail_image})`,
                                            backgroundSize: 'scale-down'
                                            , backgroundRepeat: 'no-repeat'
                                            , backgroundPosition: 'center center'
                                            , height: '450px'
                                            , width: '100%'
                                            // , marginLeft: '0px'
                                        }}>
                                        </div> */}
                                        <img
                                            src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/trackImages/${data.detail_image}`}
                                            width={"92%"}
                                            height={"auto"}
                                            style={{ marginTop: '2rem',marginLeft:'10px' }}
                                        />
                                    </div>

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className={styles.circuitBodyPart1right}>
                                        <table>
                                            <tr>
                                                <th align="left" >
                                                    {t('first_grand_prix_word')}
                                                </th>
                                                <th align="left" >
                                                    {t('number_laps_word')}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td align="left">
                                                    <p>{data.first_grand_prix}</p>
                                                </td>
                                                <td align="left">
                                                    <p>{data.number_of_laps}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th align="left">
                                                    {t('circuit_length_word')}
                                                </th>
                                                <th align="left">
                                                    {t('race_distance_word')}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td align="left">
                                                    <p>{data.circuit_length}</p>
                                                </td>
                                                <td align="left">
                                                    <p>{data.race_distance}</p>
                                                </td>
                                            </tr>
                                        </table>
                                        <table>
                                            <tr>
                                                <th align="left" colSpan={3}>
                                                    {t('lap_record_word')}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td align="left">
                                                    <p className={styles.lap_record}>{data.lap_record} </p>
                                                    <span className={styles.smallP}>
                                                        {
                                                            i18n.language === "ar" ?
                                                                <>
                                                                    {data.arabic_driver_name} - {data.year}
                                                                </>
                                                                :
                                                                <>
                                                                    {data.driver_name} - {data.year}
                                                                </>

                                                        }
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={styles.descriptionBody}>
                            <div className={styles.descriptionBodyHeader}>
                                <p>{
                                    i18n.language === "ar" ?
                                        data.circuit_title1_arabic
                                        :
                                        data.circuit_title1

                                }</p>
                            </div>
                            <div className={styles.descriptionBodyContent}>
                                <div className={styles.descriptionBodyContentBody}>
                                    <div dangerouslySetInnerHTML={{
                                        __html:
                                            i18n.language === "ar" ?
                                                data.circuit_arabic_description1
                                                :
                                                data.circuit_description1
                                    }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            data.circuit_title2 != ''
                                ?
                                <>

                                    <div className={styles.descriptionBody}>
                                        <div className={styles.descriptionBodyHeader}>
                                            <p>{
                                                i18n.language === "ar" ?
                                                    data.circuit_title2_arabic
                                                    :
                                                    data.circuit_title2

                                            }</p>
                                        </div>
                                        <div className={styles.descriptionBodyContent}>
                                            <div className={styles.descriptionBodyContentBody}>
                                                <div dangerouslySetInnerHTML={{
                                                    __html:
                                                        i18n.language === "ar" ?
                                                            data.circuit_arabic_description2
                                                            :
                                                            data.circuit_description2
                                                }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }

                        {
                            data.circuit_title3 != ''
                                ?
                                <>

                                    <div className={styles.descriptionBody}>
                                        <div className={styles.descriptionBodyHeader}>
                                            <p>{
                                                i18n.language === "ar" ?
                                                    data.circuit_title3_arabic
                                                    :
                                                    data.circuit_title3

                                            }</p>
                                        </div>
                                        <div className={styles.descriptionBodyContent}>
                                            <div className={styles.descriptionBodyContentBody}>
                                                <div dangerouslySetInnerHTML={{
                                                    __html:
                                                        i18n.language === "ar" ?
                                                            data.circuit_arabic_description3
                                                            :
                                                            data.circuit_description3
                                                }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }

                        {
                            data.circuit_title4 != ''
                                ?
                                <>

                                    <div className={styles.descriptionBody}>
                                        <div className={styles.descriptionBodyHeader}>
                                            <p>{
                                                i18n.language === "ar" ?
                                                    data.circuit_title4_arabic
                                                    :
                                                    data.circuit_title4

                                            }</p>
                                        </div>
                                        <div className={styles.descriptionBodyContent}>
                                            <div className={styles.descriptionBodyContentBody}>
                                                <div dangerouslySetInnerHTML={{
                                                    __html:
                                                        i18n.language === "ar" ?
                                                            data.circuit_arabic_description4
                                                            :
                                                            data.circuit_description4
                                                }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }

                        {
                            data.circuit_title5 != ''
                                ?
                                <>

                                    <div className={styles.descriptionBody}>
                                        <div className={styles.descriptionBodyHeader}>
                                            <p>{
                                                i18n.language === "ar" ?
                                                    data.circuit_title5_arabic
                                                    :
                                                    data.circuit_title5

                                            }</p>
                                        </div>
                                        <div className={styles.descriptionBodyContent}>
                                            <div className={styles.descriptionBodyContentBody}>
                                                <div dangerouslySetInnerHTML={{
                                                    __html:
                                                        i18n.language === "ar" ?
                                                            data.circuit_arabic_description5
                                                            :
                                                            data.circuit_description5
                                                }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }
                    </Grid>
            }
        </>
    );
};

export default ScheduleCircuit;
