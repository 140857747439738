import React from "react";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { LinearProgress } from "@mui/material";
import arrow from "../../assets/images/long-arrow-down.png";

const CustomDataTable = (props) => {
  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: props.isLoading ?
          <LinearProgress />
          :
          "There is no matching data to display"
        ,
      },
    },
  };
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        options={props.options ? props.options : options}
        columns={props.columns}
        data={props.isLoading ? [] : props.data}
        title={props.title}
      />
    </MuiThemeProvider>
  );
};
CustomDataTable.propTypes = {
  isLoading: PropTypes.any,
  theme: PropTypes.any,
  options: PropTypes.any,
  columns: PropTypes.any,
  data: PropTypes.any,
  title: PropTypes.any,
};
function getMuiTheme() {
  return createTheme({
    overrides: {
      MUIDataTable: {
        root: {},
        paper: {
          boxShadow: "none",
        },
        responsiveBase: {
          height: "calc(100vh - 180px)!important",
        },
      },
      MUIDataTableBodyRow: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#ebebeb",
          },
        },
      },
      MUIDataTableBodyCell: {
        root: {
          paddingTop: "7px",
          paddingBottom: "7px",
          fontSize: "url.92em",
        },
      },

      MuiTableCell: {
        root: {
          paddingTop: "2px",
          paddingBottom: "2px",
          fontSize: "0.92em",
        },
      },

      MuiTableHead: {
        root: {
          "& tr": {
            "& th": {
              "&span ": {
                "& button": {},
              },
            },
          },
        },
      },

      MUIDataTableHeadCell: {
        data: {
          fontFamily: "League Spartan",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "60px",
          textAlign: "center",
          textTransform: "uppercase",
          color: "#FFFFFF",

          // "& :after": {
          //   content: `url(${arrow})`,
          // },
        },
      },
      MuiTableCell: {
        head: {
          backgroundColor: "#544F50 !important",
        },
      },
    },
  });
}

export default CustomDataTable;
