import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/TicketCard.module.css";
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button, CardActionArea } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Book from "./Book";
import Receipt from "./Receipt";

const TicketCard = (props) => {
    const [data, setData] = useState(props.data);
    const [dataRec, setDataRec] = useState(props.data);
    const { t, i18n } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [visibleRec, setVisibleRec] = useState(false);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState('');
    const [ticketNumber, setTicketNumber] = useState('');

    const openDialog = (id, ticket_number) => {
        setId(id);
        setTicketNumber(ticket_number);
        setVisible(true);
    }
    const handleClose = (recData) => {
        setVisible(false);
        if (recData.length > 0) {
            setDataRec(recData);
            setVisibleRec(true);
        }
    };

    const handleCloseRec = ()=>{
        setVisibleRec(false);
        props.handleClose(true);

    }
    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    return (
        <>
            <div className={styles.card}>
                <div className={styles.header}>
                    <CalendarMonthIcon style={{ fontSize: '35px', marginLeft: '28%', float: 'left' }} />
                    <div>{data.date}</div>
                </div>
                <div className={styles.body}>
                    <div className={styles.withBorder}>
                        <p>{t('section_word')}</p>
                        {
                            i18n.language ==="ar"
                            ?
                            <span>{data.arabic_race_name}</span>
                            :
                            <span>{data.race_name}</span>
                        }
                    </div>
                    <div className={styles.withBorder}>
                        <p>{t('row_word')}</p>
                        <span>{data.row}</span>
                    </div>
                    <div className={styles.withBorder}>
                        <p>{t('seat_word')}</p>
                        <span>{data.seat}</span>
                    </div>
                    <div style={i18n.language ==="ar" ? {width: '50%', float: 'right' }:{ width: '50%', float: 'left' }} >
                        <p>{t('type_word')}</p>
                        <span>{data.type}</span>
                    </div>
                    <div style={i18n.language ==="ar" ?{direction:'ltr'}: {textAlign: 'center'}}>
                        <Button variant="contained"
                            style={{
                                backgroundColor: '#544F50'
                                , textTransform: 'uppercase'
                                , marginTop: '20px'
                                , height: '60px'
                                , width: '150px'
                            }}
                            onClick={() => openDialog(data.id, data.ticket_number)}
                        >
                            {t('book_now')}
                        </Button>
                    </div>
                </div>
            </div>

            <Book
                open={visible}
                id={id}
                ticket_number={ticketNumber}
                handleClose={handleClose}
            />
            <Receipt
                open={visibleRec}
                data={dataRec}
                handleClose={handleCloseRec}
            />
        </>
    );
};

export default TicketCard;
