import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
import axios from "axios";
import Drawer from "./StoreDrawer";
import { Close } from "@mui/icons-material";
import styles from "../../styles/new/header2.module.css";

const SmallHeader = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [categoryID, setCategoryID] = useState("");

  const [logoSource, setLogoSource] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const mobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const category_id = localStorage.getItem("category_id");
    if (category_id) {
      setCategoryID(category_id);
    }
  }, []);

  const getPageData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "Header",
        },
      })
      .then(function (response) {
        const src = `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image1}`;
        setLogoSource((prev) => src);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getPageData();
    console.log("category" + localStorage.getItem("category_id"));
  }, []);

  useEffect(() => {
    console.log(location);
    setMenuOpen(false);
  }, [location]);

  return (
    <>
      {location.pathname.toLowerCase().includes("store") ? <Drawer /> : null}
      <div className={i18n.language === "ar" ? styles.headerNavArabic : styles.headerNav}>
        <div
          className={styles.bar}
          style={
            props.language === "ar"
              ? { direction: "rtl", textAlign: "right" }
              : null
          }
        >
          <nav className={styles.nav}>

            <NavLink
              to={`/scheduleDetails/${props.season_id}&${props.id}`}
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>{t("schedules")}</h1>
            </NavLink>
            <NavLink
              to={`/scheduleCircuit/${props.season_id}&${props.id}`}
              className={({ isActive }) =>
                isActive ? styles.active_link : styles.link
              }
            >
              <h1 className={styles.navItem}>{t("circuit_word")}</h1>
            </NavLink>
          </nav>
        </div>
      </div>
    </>
  );
};

export default SmallHeader;
