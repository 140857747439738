import React, { useState, useEffect, createRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ADS from "../../components/NewsArticles/ADS";
import styles from "../../styles/new/Teams.module.css";
import TeamCard from "../Teams/TeamCard";
import { generateArrayOfYears } from "../../helpers/FixedData";
import SingleCustomAutocomplete from "../../components/CustomAutocomplete/SingleCustomAutocomplete";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import headerBackground from '../../assets/images/teamHeader.png'
import Header2 from "../../components/Layout/Header2";
import Details from "./Details";

const Teams = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  const [teams, setTeams] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [seasonName, setSeasonName] = useState("");
  const [seasonArabicName, setSeasonArabicName] = useState("");
  const [width, setWidth] = useState(100);
  const [filterForm, setFilterForm] = useState({
    season_id: seasons.length > 0 ? seasons[0].id : null,
  });
  const [page, setPage] = useState(0);

  const [id, setId] = useState('');
  const [visible, setVisible] = useState(false);

  const getTeams = (arr, page) => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/masterData/teams/getBySeason`,
        {
          params: {
            season_id: filterForm.season_id,
            // offset: page,
          },
        }
      )
      .then(function (response) {
        let tempData = [...arr];
        let data = response.data;
        // if (data.length > 0) {
        //   for (var i = 0; i < data.length; i++) {
        //     tempData.push(data[i]);
        //   }
        // }
        setTeams(data);
        setLoading(false);

      })
      .catch(function (error) {
        console.log(error.response);
        setLoading(false);
      });
  };


  useEffect(() => {
    var result = seasons.find(obj => {
      return obj.id === filterForm.season_id
    })
    if (result) {
      setSeasonName(result.name);
      setSeasonArabicName(result.arabic_name);
    }
    let data = [];
    getTeams(data, 0);

  }, [filterForm.season_id]);

  useEffect(() => {
    if (page > 0) {
      let data = teams;
      getTeams(data, page);
    }
  }, [page]);


  useEffect(() => {
    const getSeasons = () => {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/masterData/seasons/get`,
          {
          }
        )
        .then(function (response) {
          setSeasons(response.data);
          setFilterForm({
            ...filterForm,
            season_id: response.data.length > 0 ? response.data[0].id : null,
          });
        })
        .catch(function (error) {
          console.log(error.response);
        });
    };
    getSeasons();
  }, []);

  const customOnChange = (newValue, name) => {
    setFilterForm((prev) => ({ ...prev, [name]: newValue }));
  };

  const getPageData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "Team",
        },
      })
      .then(function (response) {
        setPageData((prev) => response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error.response);
        setLoading(false);
      });
  };
  useEffect(() => {
    getPageData();
  }, []);


  const openDialog = (id) => {
    setId(id);
    setVisible(true);
  }
  const handleClose = () => {
    setVisible(false);
  };

  const loadMore = () => {
    setPage(page + 1);
  };

  return (
    <>
      {
        loading ? <CircularProgress />
          :

          <Grid container style={{ backgroundColor: '#F0F0F0' }}>
            <div className={styles.header} style={{ backgroundImage: `url(${headerBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
              <div className={styles.headerContent}>
                <h3>FORMULA 1 </h3>
                <p>teams </p>
              </div>
            </div>
            <Grid item xs={12} md={12}>
              <div className={styles.subHeaderMenu}>
                <Header2 />
              </div>
            </Grid>

            <ADS />

            <div className={styles.subHeaderLine}>
              <p className={styles.filter} style={props.language === "ar" ? { float: 'left', textAlign: "left" } : null}>
                <SingleCustomAutocomplete
                  filedName="season_id"
                  label={t('filter_year')}
                  list={seasons}
                  value={filterForm.season_id}
                  listKey="id"
                  description="name"
                  customOnChange={customOnChange}
                />
              </p>
            </div>
            <div className={styles.CardsSection}>
              <Grid container spacing={10}>
                {
                  teams.map((e, index) => (
                    <>

                      <Grid item md={6} xs={12} key={index}>
                        <div onClick={() => {
                          openDialog(e.data.id)
                        }}>
                          <TeamCard data={e} season_id={filterForm.season_id} />
                        </div>
                      </Grid>
                    </>
                  ))
                }
              </Grid>
            </div>

            {/* <div className={styles.viewsPar}>
              <div className={styles.viewsContainer}>
                <p>You've viewed {teams.length} of 45 news</p>
              </div>
            </div>

            <div className={styles.loadingBtn}>
              <div className={styles.loadingBtnContainer} onClick={() => { loadMore() }}>
                <p>load more</p>
              </div>
            </div> */}
            <Details
              open={visible}
              data={teams}
              id={id}
              season_id={filterForm.season_id}
              handleClose={handleClose}
            />
          </Grid>
      }
    </>
  );
};

export default Teams;
