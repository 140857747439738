import React, { useState, useEffect } from "react";
import styles from "../../styles/new/Employees.module.css";
import { CircularProgress, Grid } from "@mui/material";
import EmployeeCard from "./EmployeeCard";
import axios from "axios";

const Employees = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    let counter = 0;
    let counter2 = 0;

    const getData = (arr, page) => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL}/data/employees/get`,
                {
                    params: {
                    },
                }
            )
            .then(function (response) {
                setData(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error.response);
                setLoading(false);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div style={{ background: "#F0F0F0" }}>
            <div className={styles.aboutusValue}>
                <h1>Team</h1>
            </div>
            {
                loading ? <CircularProgress />
                    :
                    <>
                        <Grid
                            container
                            spacing={1}
                            style={{width:'70%',margin:'auto' }}
                        >
                            {
                                data.map((e, index) => {
                                    counter++;
                                    return (
                                        <>
                                            {
                                                counter < 4
                                                    ?
                                                    <Grid item xs={12} md={4} key={index}>
                                                        <div className={styles.EmployeeCardSection}><EmployeeCard data={e} /></div>
                                                    </Grid>
                                                    :
                                                    null
                                            }
                                        </>
                                    )
                                })
                            }
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            style={{width:'90%',margin:'auto' }}
                        >
                            {
                                data.map((e, index) => {
                                    counter2++;
                                    return (
                                        <>
                                            {
                                                counter2 < 4
                                                    ?
                                                    null
                                                    :
                                                    <>
                                                        <Grid item xs={12} md={3} key={index}>
                                                            <div className={styles.EmployeeCardSection}><EmployeeCard data={e} /></div>
                                                        </Grid>
                                                    </>
                                            }
                                        </>
                                    )
                                })
                            }
                        </Grid>

                    </>

            }

        </div>
    );
};

export default Employees;
