import React from "react";
import ShareTicket from "./ShareTicket";
import Hero from "./Hero";
import styles from "../../styles/new/ShareWithUs.module.css";
import LatestShares from "./LatestShares";
import ShareMore from "./ShareMore";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const ShareWithUs = () => {
  const [latest, setLatest] = useState([]);
  const [total, setTotal] = useState(0);

  const [currentPage, setCurrentPage] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [dataEnded, setDataEnded] = useState(false);

  const getLatestShares = () => {
    if (dataEnded && latest.length > 0 && currentPage != 0) {
      return;
    }
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/masterData/shares/get/approved`,
        { params: { page: currentPage } }
      )
      .then((res) => {
        setIsLoading(false);
        if (res.data.length > 0) {
          let temp = latest;
          const more = temp.concat(res.data);

          setLatest(more);
        } else {
          setDataEnded(true);
        }

        // setLatest(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getTotal = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/masterData/shares/get/approved/total`,
        {}
      )
      .then((res) => setTotal(res.data))
      .catch((err) => err.response);
  };

  useEffect(() => {
    getTotal();
    getLatestShares();
  }, []);

  useEffect(() => {
    if (currentPage === 0) {
      setLatest([]);
    }
    getLatestShares();
  }, [currentPage]);

  return (
    <div className={styles.main}>
      <Hero />
      <ShareTicket />
      <ShareMore />
      <LatestShares
        latest={latest}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        total={total}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ShareWithUs;
