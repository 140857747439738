
export const ticketPriorities = [
  { id: "1", label: "Low" },
  { id: "2", label: "Medium" },
  { id: "3", label: "Urgent" },
];

export const paymentTypes = [
  { id: "cod", label: "COD" },
  { id: "pp", label: "PP" },
];

export const daysList = [
  { id: "Monday", label: "Monday" },
  { id: "Tuesday", label: "Tuesday" },
  { id: "Wednesday", label: "Wednesday" },
  { id: "Thursday", label: "Thursday" },
  { id: "Friday", label: "Friday" },
  { id: "Saturday", label: "Saturday" },
  { id: "Sunday", label: "Sunday" },
];



export function generateArrayOfYears() {
  var max = new Date().getFullYear()
  var min = max - 10
  var years = []

  for (var i = max; i >= min; i--) {
    years.push({
      id:i,name:i
    })
  }
  return years
} 