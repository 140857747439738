import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/NewsArticels.module.css";
import { useTranslation } from "react-i18next";

const ADS = (props) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState(props.data ? props.data : []);
    return (
        <>
            <div className={styles.ads}>
                {
                    data.ads_image
                        && data.ads_image != ''
                        && data.ads_image != null
                        ?
                        <div style={{
                            backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${data.ads_image})`
                            , backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                            , width: '100%'
                            ,height:'100%'
                        }}>
                            {/* <img
                                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${data.ads_image}`}
                                height={"35"}
                                width={"45"}
                                style={{ borderRadius: "10px" }}
                                loading={"lazy"}
                            /> */}
                            
                        </div>
                        :
                        <h1>{t('ads_here')}</h1>
                }
            </div>
        </>
    );
};

export default ADS;
