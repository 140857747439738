import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = (props) => {
  return (
    <>
      <Header language={props.language} handleChangeLanguage={props.handleChangeLanguage}/>
      <div>{props.children}</div>
      <Footer />
    </>
  );
};

export default Layout;
