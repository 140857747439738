import React, { useState, useEffect, createRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ADS from "../../components/NewsArticles/ADS";
import styles from "../../styles/new/Schedules.module.css";
import SingleCustomAutocomplete from '../../components/CustomAutocomplete/SingleCustomAutocomplete';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import headerBackground from '../../assets/images/schedulesImg.png'
import Header2 from "../../components/Layout/Header2";
import SheduleCard from "../Schedules/SheduleCard";

const Schedules = (props) => {
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState({});
    const [schedules, setSchedules] = useState([]);
    const [width, setWidth] = useState(100);
    const [seasons, setSeasons] = useState([]);
    const [page, setPage] = useState(0);

    const [filterForm, setFilterForm] = useState({
        season_id: seasons.length > 0 ? seasons[0].id : null,
    });

    const getPageData = () => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
                params: {
                    name: "Schedule",
                },
            })
            .then(function (response) {
                setPageData((prev) => response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error.response);
                setLoading(false);
            });
    };
    useEffect(() => {
        getPageData();
    }, []);

    useEffect(() => {
        const getSeasons = () => {
            axios
                .get(
                    `${process.env.REACT_APP_API_BASE_URL}/masterData/seasons/get`,
                    {
                        params: {
                            season_id: filterForm.season_id,
                        },
                    }
                )
                .then(function (response) {
                    setSeasons(response.data);
                    setFilterForm({
                        ...filterForm,
                        season_id: response.data.length > 0 ? response.data[0].id : null,
                    });
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        };
        getSeasons();
    }, []);

    const getSchedules = (arr, page) => {
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/data/races/getSchedules`, {
                params: {
                    season_id: filterForm.season_id,
                    offset: page,
                }
            })
            .then(function (response) {
                let tempData = [...arr];
                let data = response.data;
                if (data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        tempData.push(data[i]);
                    }
                }
                setSchedules(tempData);
            })
            .catch(function (error) {
                console.log(error.response);
            });
    };

    useEffect(() => {
        let data = [];
        getSchedules(data, 0);
    }, [filterForm.season_id]);

    useEffect(() => {
        if (page > 0) {
            let data = schedules;
            getSchedules(data, page);
        }
    }, [page]);

    const customOnChange = (newValue, name) => {
        setFilterForm(prev => ({ ...prev, [name]: newValue }));
    };

    const loadMore = () => {
        setPage(page + 1);
    };
    return (
        <>
            <div style={{ paddingTop: "10px" }}>

                {
                    loading ? <CircularProgress />
                        :
                        <Grid container style={{ backgroundColor: '#F0F0F0' }}>
                            <div className={styles.header} style={{ backgroundImage: `url(${headerBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                            </div>
                            <Grid item xs={12} md={12}>
                                <div className={styles.subHeaderMenu}>
                                    <Header2 />
                                </div>
                            </Grid>

                            <ADS />

                            <div className={styles.subHeaderLine}>
                                <p className={styles.filter} style={i18n.language === "ar" ? { float: 'left', textAlign: "left" } : null}>
                                    <SingleCustomAutocomplete
                                        filedName="season_id"
                                        label={t('filter_year')}
                                        list={seasons}
                                        value={filterForm.season_id}
                                        listKey="id"
                                        description="name"
                                        customOnChange={customOnChange}
                                    />
                                </p>
                            </div>
                            <div className={styles.CardsSection}>
                                <Grid container spacing={2}
                                    direction="row"
                                // alignItems="center"
                                // justifyContent="center"
                                >
                                    {
                                        schedules.map((e, index) => (
                                            // <Grid Container columnSpacing={2} style={{marginTop:'40px'}}>
                                            <>
                                                <Grid item md={6} xl={4} xs={12}>
                                                    <Link
                                                        to={`/scheduleDetails/${filterForm.season_id}&${e.id}`}
                                                        className={styles.link}
                                                    >
                                                        <SheduleCard data={e} season_id={filterForm.season_id} />
                                                    </Link>
                                                </Grid>
                                            </>
                                            // </Grid>
                                        ))
                                    }

                                </Grid>
                            </div>

                            <div className={styles.viewsPar}>
                                <div className={styles.viewsContainer}>
                                    <p style={i18n.language === "ar" ? { direction: "rtl", textAlign: "center" } : null}>{t('You_ve_viewed_number')} {schedules.length}</p>
                                </div>
                            </div>

                            <div className={styles.loadingBtn}>
                                <div className={styles.loadingBtnContainer} onClick={() => { loadMore() }}>
                                    <p>{t('load_more')}</p>
                                </div>
                            </div>
                        </Grid>
                }
            </div>
        </>
    );
};

export default Schedules;
