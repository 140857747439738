import React from "react";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/NewsArticels.module.css";
import RearWing from '../../assets/images/rear-wing.jpeg';
import { useTranslation } from "react-i18next";

const NewsCard = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <>

            <div className={styles.newsCard}>
                <div className={styles.imageContainer}>
                    <img
                        width={'100%'}
                        height={'100%'}
                        src={RearWing} />
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.rightContainerTable}>
                    <h3>
                        {
                            i18n.language === "ar" ?
                                props.data.arabic_title
                                :
                                props.data.title

                        }
                    </h3>
                    <div className={styles.rightContainerPar}>
                        {
                             <div
                             dangerouslySetInnerHTML={{
                               __html:
                                 i18n.language === "ar" ?
                                   props.data.arabic_description
                                   :
                                   props.data.description
                             }}
                           ></div>
                        }
                        <div className={styles.rightReadMoreBtn}>
                            <p>{t('read_more_word')}</p>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default NewsCard;
