import React from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';


// export const CustomDate = props => {
//   return (
//     <FormControl fullWidth={true}>
//       <MuiPickersUtilsProvider utils={DateFnsUtils}>
//         <KeyboardDatePicker
//           disableToolbar
//           variant="inline"
//           format="dd/MM/yyyy"
//           margin="normal"
//           id={props.id}
//           label={props.label}
//           value={props.value}
//           style={{ width: '100%', margin: 0 }}
// onChange={val => {
//   props.onChange({ target: { id: props.id, value: new Date(val) } });
// }}
//           KeyboardButtonProps={{
//             'aria-label': 'change date',
//           }}
//         />
//       </MuiPickersUtilsProvider>
//     </FormControl>
//   );
// };
export const CustomDate = props => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label={props.label}
          inputFormat="DD/MM/YYYY"
          value={props.value}
          onChange={val => {
            props.onChange({ target: { id: props.id, value: new Date(val) } });
          }}
          id={props.id}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
}

CustomDate.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.node,
    onChange: PropTypes.func,
  }).isRequired,
}).isRequired;
