import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/Tickets.module.css";
import SingleCustomAutocomplete from '../../components/CustomAutocomplete/SingleCustomAutocomplete';
import { useTranslation } from "react-i18next";
import headerBackground from '../../assets/images/ticketsBg.png'
import { Button } from "@mui/material";
import { CustomDate } from "../../components/Inputs/CustomInputForms";
import * as moment from 'moment'
import TicketCard from "./TicketCard";
const type = [
    {
        id: "adult", name: "Adult"
    },
    {
        id: "child", name: "Child"
    },
];


const Tickets = (props) => {
    const { t, i18n } = useTranslation();
    const [seasons, setSeasons] = useState([]);
    const [races, setRaces] = useState([]);
    const [rows, setRows] = useState([]);
    const [seats, setSeats] = useState([]);
    const [data, setData] = useState([]);
    const [pageData, setPageData] = useState({});
    const [loading, setLoading] = useState(false);

    const [filterForm, setFilterForm] = useState({
        season_id: seasons.length > 0 ? seasons[0].id : null,
        section: null,
        row: null,
        seat: null,
        type: null,
        date: Date.now(),
    });

    // const getPageData = () => {
    //     setLoading(true);
    //     axios
    //         .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
    //             params: {
    //                 name: "Schedule",
    //             },
    //         })
    //         .then(function (response) {
    //             setPageData((prev) => response.data);
    //             setLoading(false);
    //         })
    //         .catch(function (error) {
    //             console.log(error.response);
    //             setLoading(false);
    //         });
    // };
    // useEffect(() => {
    //     getPageData();
    // }, []);

    const getData = () => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/masterData/tickets/getAvailable`, {
                params: {
                    date: moment(filterForm.date).format('YYYY-MM-DD'),
                    seat: filterForm.seat,
                    row: filterForm.row,
                    race: filterForm.section,
                    type: filterForm.type,
                },
            })
            .then(function (response) {
                setData(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error.response);
                setLoading(false);
            });
    };
    useEffect(() => {
        getData();
    }, []);

    const checkValidity = () => {
        getData();
    }

    useEffect(() => {
        const getSeasons = () => {
            axios
                .get(
                    `${process.env.REACT_APP_API_BASE_URL}/masterData/seasons/get`,
                    {
                        params: {
                            season_id: filterForm.season_id,
                        },
                    }
                )
                .then(function (response) {
                    setSeasons(response.data);
                    setFilterForm({
                        ...filterForm,
                        season_id: response.data.length > 0 ? response.data[0].id : null,
                    });
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        };
        const getRaces = () => {
            axios
                .get(
                    `${process.env.REACT_APP_API_BASE_URL}/masterData/races/getByActiveSeason`,
                    {
                    }
                )
                .then(function (response) {
                    setRaces(response.data);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        };
        // const getSeats = () => {
        //     axios
        //         .get(
        //             `${process.env.REACT_APP_API_BASE_URL}/masterData/tickets/getSeats`,
        //             {
        //             }
        //         )
        //         .then(function (response) {
        //             setSeats(response.data);

        //         })
        //         .catch(function (error) {
        //             console.log(error.response);
        //         });
        // };
        // const getRows = () => {
        //     axios
        //         .get(
        //             `${process.env.REACT_APP_API_BASE_URL}/masterData/tickets/getRows`,
        //             {
        //             }
        //         )
        //         .then(function (response) {
        //             setRows(response.data);
        //         })
        //         .catch(function (error) {
        //             console.log(error.response);
        //         });
        // };
        if (seasons.length == 0) {
            getSeasons();
        }
        // if (rows.length == 0) {
        //     getRows();
        // }
        // if (seats.length == 0) {
        //     getSeats();
        // }
        if (races.length == 0) {
            getRaces();
        }
    }, []);

    // const getSchedules = (arr, page) => {
    //     axios
    //         .get(`${process.env.REACT_APP_API_BASE_URL}/masterData/races/getBySeason`, {
    //             params: {
    //                 season_id: filterForm.season_id
    //             }
    //         })
    //         .then(function (response) {
    //             setSchedules(response.data);
    //         })
    //         .catch(function (error) {
    //             console.log(error.response);
    //         });
    // };

    // useEffect(() => {
    //     getSchedules();
    // }, [filterForm.season_id]);

    const customOnChange = (newValue, name) => {
        setFilterForm(prev => ({ ...prev, [name]: newValue }));
    };

    const handleInputChange = e => {
        const { id, name, value, checked, type } = e.target;
        // console.log(moment(value).format('YYYY-MM-DD'));
        setFilterForm({
            ...filterForm,
            [id]: value,
        });
    };
    const handleClose = (flag) => {
        if (flag) {
            getData();
        }
    };

    return (
        <>
            <div style={{ paddingTop: "10px" }}>
                <Grid container style={{ backgroundColor: '#F0F0F0' }}>
                    <div className={styles.header} style={{ backgroundImage: `url(${headerBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                        <div className={styles.headerContent}>
                            <div>
                                <h3>{t('enjoy_with_us')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className={styles.subHeader}>
                        <div className={styles.subHeaderContent}>
                            <Grid container spacing={4}>
                                <Grid item xs={10} md={5}>
                                    <div>
                                        <p style={i18n.language === "ar" ? {textAlign: "left",marginLeft:'40%'} : null}>{t('section_word')}</p>
                                        <div className={styles.filter} style={i18n.language === "ar" ? { float: 'left', textAlign: "left" } : null}>
                                            <SingleCustomAutocomplete
                                                filedName="section"
                                                label={filterForm.section ? null :t('section_word')}
                                                list={races}
                                                value={filterForm.section}
                                                listKey="id"
                                                description="name"
                                                customOnChange={customOnChange}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                {/* <Grid item xs={2} md={1}>
                                    <div>
                                        <p>row</p>
                                        <div className={styles.filter} style={i18n.language === "ar" ? { float: 'left', textAlign: "left" } : null}>
                                            <SingleCustomAutocomplete
                                                filedName="row"
                                                label={'Row'}
                                                list={rows}
                                                value={filterForm.row}
                                                listKey="name"
                                                description="name"
                                                customOnChange={customOnChange}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2} md={1}>
                                    <div>
                                        <p>seat</p>
                                        <div className={styles.filter} style={i18n.language === "ar" ? { float: 'left', textAlign: "left" } : null}>
                                            <SingleCustomAutocomplete
                                                filedName="seat"
                                                label={'Seat'}
                                                list={seats}
                                                value={filterForm.seat}
                                                listKey="name"
                                                description="name"
                                                customOnChange={customOnChange}
                                            />
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={5} md={2}>
                                    <div>
                                        <p>type</p>
                                        <div className={styles.filter} style={i18n.language === "ar" ? { float: 'left', textAlign: "left" } : null}>
                                            <SingleCustomAutocomplete
                                                filedName="type"
                                                label={'Type'}
                                                list={type}
                                                value={filterForm.type}
                                                listKey="id"
                                                description="name"
                                                customOnChange={customOnChange}
                                            />
                                        </div>
                                    </div>
                                </Grid> */}

                                <Grid item xs={8} md={3}>
                                    <div>
                                        <p style={i18n.language === "ar" ? {textAlign: "left",marginLeft:'40%'} : null}>{t('date_word')}</p>
                                        <div className={styles.filter} style={i18n.language === "ar" ? { float: 'left', textAlign: "left" } : null}>
                                            <CustomDate
                                                label={filterForm.date ? null : t('date_word')}
                                                id={'date'}
                                                value={filterForm.date}
                                                onChange={handleInputChange} />

                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <div className={styles.subHeaderContentBtn}>
                                        <Button variant="contained" style={{ backgroundColor: '#BF2A30', height: '50px' }}
                                            onClick={checkValidity}
                                        >{t('check_availability')}</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className={styles.body}  style={i18n.language ==="ar" ?{direction:'rtl'}: null}>
                        <div className={styles.bodyContent}>
                            <p className={styles.bodyContentTitle}>{t('available_seats')}</p>
                        </div>
                        <div className={styles.subHeaderLine}>
                            <div className={styles.subHeaderLineContent}>
                            </div>
                        </div>
                    </div>
                    <div className={styles.cardSection} style={i18n.language ==="ar" ?{direction:'rtl'}: null}>
                        <Grid container spacing={10}>
                            {
                                data.length > 0 ?
                                    data.map((e, index) => (
                                        <Grid item xs={12} md={6} lg={6} xl={4} key={index}>
                                            <TicketCard data={e} handleClose={handleClose} />
                                        </Grid>
                                    ))
                                    :
                                    <Grid item xs={12} md={6} lg={6} xl={4}>
                                        <div>
                                            No available tickets for the selected section and date
                                        </div>
                                    </Grid>

                            }
                        </Grid>
                    </div>
                </Grid>
            </div>
        </>
    );
};

export default Tickets;
