import { CircularProgress, Container, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../styles/new/ShareWithUs.module.css";
const LatestShares = ({
  latest,
  currentPage,
  setCurrentPage,
  total,
  isLoading,
}) => {
  const { t, i18n } = useTranslation();

  console.log("latest", latest);
  return (
    <div>
      <div className={styles.latest} style={i18n.language ==="ar" ?{direction:'rtl'}:null}>
        <div className={styles.latestContent}>
          <p className={styles.latestContentTitle}>{t('latest_shares')}</p>
        </div>
        <div className={styles.subHeaderLine}>
          <div className={i18n.language==="ar" ?styles.subHeaderLineContentAr:styles.subHeaderLineContent}></div>
        </div>
      </div>
      <Container style={{ marginTop: 50, padding: 0 }} maxWidth={"lg"}>
        <Grid container spacing={4}>
          {latest.length > 0 &&
            latest.map((shared, index) => (
              <>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12} key={index}>
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: 50,
                    }}
                  >
                    {shared.file.includes("mp4") ? (
                      <video
                        width="300"
                        height="350"
                        controls
                        style={{ objectFit: "cover" }}
                      >
                        <source
                          src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/sharesFiles/${shared.file}`}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : shared.file.includes("pdf") ? (
                      <object
                        style={{ objectFit: "cover" }}
                        width="300"
                        height="350"
                        data={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/sharesFiles/${shared.file}`}
                        type="application/pdf"
                      >
                        {" "}
                      </object>
                    ) : (
                      <img
                        style={{ objectFit: "cover" }}
                        width="300"
                        height="350"
                        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/sharesFiles/${shared.file}`}
                      />
                    )}
                    <h2>{shared.name}</h2>
                  </div>
                </Grid>
              </>
            ))}
        </Grid>
      </Container>

      <div className={styles.latestBottom}>
        <h1>{t('You_ve_viewed')} {latest.length}</h1>
        <div className={styles.seperator}></div>
      </div>

      <div className={styles.loadMore}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <button onClick={() => setCurrentPage((prev) => prev + 1)}>
            {t('load_more')}
          </button>
        )}
      </div>
    </div>
  );
};

export default LatestShares;
