import React from "react";
import styles from "../../styles/AboutUsValues.module.css";
import { CircularProgress, Grid } from "@mui/material";

import card1 from "../../assets/images/passion-respect.png";
import card2 from "../../assets/images/integrity.png";
import card3 from "../../assets/images/team-spirit.png";
import card4 from "../../assets/images/awareness-learning.png";
import card5 from "../../assets/images/fanaticism-extremism.png";
import background from '../../assets/images/bg_flag2.png'
import { useTranslation } from "react-i18next";

const Values = () => {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ background: "#F9F9F9", width: '100%' }}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          container
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <div className={styles.card}>
            <img src={card1} />
            {
              i18n.language === "ar"
                ?
                <h1>الشغف والاحترام للرياضة</h1>
                :
                <h1>Passion and respect for the sport</h1>
            }
            {
              i18n.language === "ar"
                ?
                <h2 style={{ textAlign: 'center' }}>نحن نحب عملنا ، ونشعر بأننا محظوظون للقيام بما نقوم به. نحن نرفض الآراء المتطرفة ونتبنى قيمًا إنسانية عالية</h2>
                :
                <h2>
                  We love our work, and we feel lucky to do what we do. We reject
                  radical views and adopt high human values
                </h2>
            }
          </div>
        </Grid>

        <Grid
          item
          container
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{ position: 'relative' }}
        >
          <div className={styles.card}>
            <img src={card2} />
            {
              i18n.language === "ar"
                ?
                <h1>النزاهة</h1>
                :
                <h1>Integrity</h1>
            }
            {
              i18n.language === "ar"
                ?
                <h2>
                  نحن ملتزمون بالحفاظ على أعلى معايير نزاهة النشر
                </h2>
                :
                <h2>
                  We are committed to maintaining the highest standards of
                  publishing integrity
                </h2>
            }

          </div>
        </Grid>

        <Grid
          item
          container
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{ position: 'relative' }}
        >
          <div className={styles.bigCard} style={{ backgroundImage: `url(${background})`, backgroundSize: '65%', backgroundRepeat: 'no-repeat', backgroundPositionX: 'right', backgroundPositionY: 'bottom' }}>
            {
              i18n.language === "ar"
                ?
                <p>قيمنا</p>
                :
                <p>OUR VALUES</p>
            }
          </div>
        </Grid>
        <Grid
          item
          container
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{ marginTop: '50px' }}
        >
          <div className={styles.card}>
            <img src={card3} />
            {
              i18n.language === "ar"
                ?
                <>
                  <h1>روح الفريق</h1>
                  <h2>نحن نسعى جاهدين لنكون الأفضل في أنفسنا بينما نتذكر أننا لا شيء بدون بعضنا البعض</h2>
                </>
                :
                <>
                  <h1> Team spirit</h1>
                  <h2>
                    We strive to be our best in ourselves while remembering that we
                    are nothing without one another
                  </h2>
                </>
            }
          </div>
        </Grid>

        <Grid
          item
          container
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{ marginTop: '50px' }}

        >
          <div className={styles.card}>
            <img src={card4} />
            {
              i18n.language === 'ar'
                ?
                <>
                  <h1>الوعي والتعلم</h1>
                  <h2>التعلم عملية مستمرة. نحن نشجع على فهم ومعرفة رياضة الفورمولا ون</h2>
                </>
                :
                <>
                  <h1>Awareness and learning</h1>
                  <h2>
                    Learning is an ongoing process. We promote the understanding and
                    knowledge of the F1 sport
                  </h2>
                </>
            }

          </div>
        </Grid>

        <Grid
          item
          container
          xl={4}
          lg={4}
          md={4}
          sm={12}
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{ marginTop: '50px' }}

        >
          <div className={styles.card}>
            <img src={card5} />
            {
              i18n.language === "ar"
                ?
                <>
                  <h1> التعصب والتطرف</h1>
                  <h2>
                    القاعدة الجماهيرية هي عنصر مهم في أي رياضة ، لكن التعصب ليس كذلك. نحن لا نؤيد الكراهية والتعصب الأعمى أو أي عمل سلبي ضد الروح الإنسانية والروح الرياضية
                  </h2>
                </>
                :
                <>
                  <h1> Fanaticism and extremism</h1>
                  <h2>
                    Fandom is an important element of any sport, fanaticism is not. We
                    do not endorse hatred bigotry or any negative action against human
                    kindness and sportsmanship
                  </h2>
                </>
            }

          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Values;
