import React, { useState, useEffect, createRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/SchedulesDetails.module.css";
import { useParams } from 'react-router-dom';
import { CircularProgress } from "@mui/material";
import CustomTabs from '../../components/CustomTabs/CustomTabs'
import CustomListItems from "./CustomListItems/CustomListItems";
import SouthEastIcon from '@mui/icons-material/SouthEast';
import CircuitPage from "./CircuitPage";
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import headerBackground from '../../assets/images/schedulesImg.png'
import cardBackground from '../../assets/images/bg_flag.png'
import SmallHeader from "../../components/Layout/SmallHeader";
import DetailCard from "./DetailCard";
import RaceList from "./CustomListItems/RaceList";

const SheduleCardDetails = (props) => {
    const { season_id } = useParams();
    const { id } = useParams();
    const [show, setShow] = useState(true);
    const [value, setValue] = useState(0);
    const [data, setData] = useState([]);
    const [start, setStart] = useState("00");
    const [end, setEnd] = useState("00");
    const [month, setMOnth] = useState("");
    const [races, setRaces] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const getData = () => {
            setShow(true);
            axios
                .get(`${process.env.REACT_APP_API_BASE_URL}/data/races/getSchedulesDetails`, {
                    params: {
                        season_id: season_id,
                        id: id
                    }
                })
                .then(function (response) {
                    setData(response.data[0]);
                    setRaces(response.data['details']);
                    setStart(response.data['start_day']);
                    setEnd(response.data['end_day']);
                    setMOnth(response.data['month']);
                    setShow(false);

                })
                .catch(function (error) {
                    console.log(error.response);
                    setShow(false);
                });
        };
        getData();
        // console.log("seasonid & schedule" +id+" "+season_id);
    }, []);


    return (
        <>
            {
                show ? <CircularProgress />
                    :
                    <Grid container style={{ backgroundColor: '#F0F0F0' }}>
                        <div className={styles.header} style={{ backgroundImage: `url(${headerBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                        </div>
                        <Grid item md={12} xs={12}>
                            <div className={styles.subHeaderMenu}>
                                <SmallHeader season_id={season_id} id={id}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div className={styles.title}>
                                {t('race_weekend')}
                            </div>
                            <div className={styles.subTitle}>
                                {
                                    i18n.language === "ar" ?
                                        <>
                                            {races[0].arabic_category_name}-{races[0].arabic_track_name}-{data.season_year}
                                        </>
                                        :
                                        <>
                                            {races[0].category_name}-{races[0].track_name} {data.season_year}
                                        </>
                                }
                            </div>
                        </Grid>
                        <div className={styles.cardSection}>
                            <Grid container
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                spacing={10}>
                                <Grid container spacing={10} alignItems="center"
                                    justifyContent="center">
                                    <Grid item xs={12} md={6} lg={4}>
                                        <Card style={{ backgroundImage: `url(${cardBackground})`, backgroundSize: '40%', backgroundRepeat: 'no-repeat', backgroundPositionX: 'right', backgroundPositionY: 'bottom' }}>
                                            <CardActionArea>
                                                <div style={{ width: '100%' }}>
                                                    <Grid container>
                                                        <Grid item xs={12} md={12}>
                                                            <div className={styles.detailsCardHead}>
                                                                <div className={styles.detailsCardHeadContent} style={i18n.language === "ar" ? { direction: "rtl", textAlign: "right",marginRight:'20px' } : null}>{t('track_time')}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <Grid container style={{ height: '100%' }}>
                                                    {races.map((e, index) => (
                                                        <RaceList
                                                            data={e} />
                                                    ))}
                                                    {/* <RaceList />
                                            <RaceList />
                                            <RaceList /> */}
                                                </Grid>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} xl={4}>
                                        <div className={styles.detailsCardBodyRight} style={i18n.language === "ar" ? { direction: "rtl", textAlign: "right" } : null}>
                                            <div className={styles.detailsCardBodyRight1}>
                                                <div className={styles.detailsCardBodyRight1_content}>
                                                    {t('race_program_word')}
                                                </div>
                                            </div>
                                            <div className={styles.detailsCardBodyRight2}>
                                                <div className={styles.detailsCardBodyRight2_content}>
                                                    {t('circuit_stats_word')}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
            }
        </>
    );
};

export default SheduleCardDetails;
