import React, { useState, useEffect, createRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ADS from "../../components/NewsArticles/ADS";
import styles from "../../styles/new/Drivers.module.css";
import DriverCard from "./DriverCard";
import SingleCustomAutocomplete from "../../components/CustomAutocomplete/SingleCustomAutocomplete";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress } from "@mui/material";
import headerBackground from '../../assets/images/driverPage.png'
import Header2 from "../../components/Layout/Header2";
import Details from "./Details";
import { Link } from "react-router-dom";

const Drivers = (props) => {
  const { t, i18n } = useTranslation();
  const [drivers, setDrivers] = useState([]);
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);
  const [seasons, setSeasons] = useState([]);
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState('');
  const [page, setPage] = useState(0);

  const videoRef = createRef();
  const [width, setWidth] = useState(100);
  const [filterForm, setFilterForm] = useState({
    season_id: seasons.length > 0 ? seasons[0].id : null,
  });

  const getDrivers = (arr, page) => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/masterData/drivers/getBySeason`,
        {
          params: {
            season_id: filterForm.season_id,
            offset: page,
          },
        }
      )
      .then(function (response) {
        let tempData = [...arr];
        let data = response.data;
        console.log("data" + data);
        if (data.length > 0) {
          for (var i = 0; i < data.length; i++) {
            tempData.push(data[i]);
          }
        }
        // if (tempData.length > 0) {
        setDrivers(tempData);
        setLoading(false);
        // }
      })
      .catch(function (error) {
        console.log(error.response);
        setLoading(false);
      });
  };


  const getPageData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "Driver",
        },
      })
      .then(function (response) {
        setPageData((prev) => response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error.response);
        setLoading(false);
      });
  };
  useEffect(() => {
    getPageData();
  }, []);


  useEffect(() => {
    const getSeasons = () => {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/masterData/seasons/get`,
          {
          }
        )
        .then(function (response) {
          setSeasons(response.data);
          setFilterForm({
            ...filterForm,
            season_id: response.data.length > 0 ? response.data[0].id : null,
          });
        })
        .catch(function (error) {
          console.log(error.response);
        });
    };
    getSeasons();
  }, []);

  useEffect(() => {
    let data = [];
    getDrivers(data, 0);
  }, [filterForm.season_id]);

  const customOnChange = (newValue, name) => {
    setFilterForm((prev) => ({ ...prev, [name]: newValue }));
  };

  const openDialog = (id) => {
    setId(id);
    setVisible(true);
  }
  const handleClose = () => {
    setVisible(false);
  };

  const loadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (page > 0) {
      let data = drivers;
      getDrivers(data, page);
    }
  }, [page]);

  return (
    <>
      {
        loading ? <CircularProgress />
          :
          <Grid container style={{ backgroundColor: '#F0F0F0' }}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                {/* <p>
                  Check out this season official F1 line-up.
                  Full breakdown of drivers, points and current positions.
                  Follow your favourite F1 drivers on and off the track.
                </p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "ar" ?
                        pageData.arabic_text1
                        :
                        pageData.text1
                  }}
                  // style={i18n.language === "ar" ? { direction: "rtl"} : null}
                ></div>
                <div className={styles.headerLeftLine}></div>
              </div>
              <div className={styles.headerRight} style={{ backgroundImage: `url(${headerBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
              </div>
            </div>
            <Grid item  xs={12} md={12}>
              <div className={styles.subHeaderMenu}>
                <Header2 />
              </div>
            </Grid>
            <Grid item xs={12} md={2} style={{ marginLeft: '5%', marginRight: '3%' }}>
              <Link
                to={`/Store`}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  className={styles.shopButton}
                  variant="outline"
                >
                  {t('shop_now')}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12} md={8}>
              <ADS data={pageData} />
            </Grid>

            <div className={styles.subHeaderLine}>
              <p className={styles.filter} style={props.language === "ar" ? { float: 'left', textAlign: "left" } : null}>
                <SingleCustomAutocomplete
                  filedName="season_id"
                  label={t('filter_year')}
                  list={seasons}
                  value={filterForm.season_id}
                  listKey="id"
                  description="name"
                  customOnChange={customOnChange}
                />
              </p>
            </div>
            <div className={styles.driverCardsSection}>
              <Grid container spacing={10}>
                {
                  drivers.map((e, index) => (
                    <>
                      <Grid item md={6} lg={4} xs={12} key={index}>
                        <div onClick={() => {
                          openDialog(e.id)
                        }}>
                          <DriverCard data={e} season_id={filterForm.season_id} driver_id={e.id} />
                        </div>
                      </Grid>
                    </>
                  ))
                }
              </Grid>
            </div>

            <div className={styles.viewsPar}>
              <div className={styles.viewsContainer}>
                {/* <p>You've viewed {drivers.length} of 45 news</p> */}
                <p style={i18n.language === "ar" ? { direction: "rtl", textAlign: "center" } : null}>{t('You_ve_viewed')} {drivers.length}  {t('from_drivers_word')}</p>
              </div>
            </div>

            <div className={styles.loadingBtn}>
              <div className={styles.loadingBtnContainer} onClick={() => { loadMore() }}>
              <p>{t('load_more')}</p>
              </div>
            </div>

          </Grid>
      }

      <Details
        open={visible}
        id={id}
        season_id={filterForm.season_id}
        handleClose={handleClose}
      />
    </>
  );
};

export default Drivers;
