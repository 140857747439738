import React from "react";
import styles from "../../styles/new/Employees.module.css";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";


const EmployeeCard = (props) => {
    const { t, i18n } = useTranslation();

    const data = props.data;
    return (
        <div className={styles.card}>
            <div>
                <img
                    src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/employeesImages/${data.image}`}
                    // src={`https://www.flagdetective.com/images/download/lebanon-hi.jpg`}
                    loading={"lazy"}
                />
            </div>
            <div className={styles.textSection}>
                <p>{
                    i18n.language === "ar"
                        ? data.arabic_name
                        : data.name
                }
                    <span>{
                        i18n.language === "ar"
                            ? data.arabic_title
                            : data.title
                    }</span>
                </p>
            </div>
        </div>
    );
};

export default EmployeeCard;
