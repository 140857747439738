import React, { useState, useEffect, createRef } from "react";
import styles from "../../styles/SlideShow.module.css";
import { CircularProgress } from "@mui/material";

const delay = 3000;

const ImageShow = (props) => {
	const [index, setIndex] = useState(0);
	const [src, setSrc] = useState([]);
	const [show, setShow] = useState(true);


	useEffect(() => {
		let mySrc = [];
		let data = props.data;
		if (data.slide1_image != '') {
			mySrc.push(data.slide1_image);
		}
		if (data.slide2_image != '') {
			mySrc.push(data.slide2_image);
		}
		if (data.slide3_image != '') {
			mySrc.push(data.slide3_image);
		}
		setSrc(mySrc);
		setTimeout(
			() =>
				setIndex((prevIndex) =>
					prevIndex === src.length - 1 ? 0 : prevIndex + 1
				),
			delay
		);
		setShow(false);
		return () => { };
	}, [index])

	return (
		<div>
			{
				show ? <CircularProgress />
					:
					<div className={styles.slideshow}>
						<div className={styles.slideshowSlider} style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
							{src.map((e, index) => (
								<img
									src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/driversImages/${e}`}
									width={"100%"}
									height={"600vh"}
									loading={"lazy"}
									style={{ objectFit: 'fill'}}		
									/>

							))}
						</div>
					</div>
			}

		</div>
	);
};

export default ImageShow;
