import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";
import styles from "../../styles/new/ShareWithUs.module.css";
import attach from "../../assets/images/attach.png";
import share1 from "../../assets/images/share1.png";
import share2 from "../../assets/images/share2.png";
import share3 from "../../assets/images/share3.png";
import { textAlign } from "@mui/system";
import { toast } from "react-toastify";
import handleErrors from "../../helpers/handleErrors";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ShareMore = () => {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const [file1, setFile1] = useState([]);
  const [viewedFile1, setViewedFile1] = useState([]);

  const [file2, setFile2] = useState();
  const [viewedFile2, setViewedFile2] = useState();

  const [file3, setFile3] = useState([]);
  const [viewedFile3, setViewedFile3] = useState([]);

  const [name, setName] = useState("");

  const handleFile1 = (e) => {
    const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    console.log(file);
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFile1({ id: randomId, file: file });
      setViewedFile1({ id: randomId, path: reader.result });
    };
  };

  const removeFile1 = () => {
    setFile1([]);
    setViewedFile1([]);
  };

  const handleFile2 = (e) => {
    const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFile2(file);
      setViewedFile2(reader.result);
      console.log(reader.result);
    };
  };

  const removeFile2 = () => {
    setFile2();
    setViewedFile2();
  };

  const handleFile3 = (e) => {
    const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    console.log(file);
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFile3({ id: randomId, file: file });
      setViewedFile3({ id: randomId, path: reader.result });
    };
  };

  const removeFile3 = () => {
    setFile3([]);
    setViewedFile3([]);
  };

  const share = (type) => {
    setLoading(true);
    const data = new FormData();

    data.append("name", name);

    if (type == 1) {
      data.append("file", file1.file);
    } else if (type == 2) {
      data.append("file", file2);
    } else {
      data.append("file", file3.file);
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/masterData/shares/add`,
      data: data,
    })
      .then((response) => {
        setName("");

        setViewedFile1([]);
        setFile1([]);

        setViewedFile2();
        setFile2();

        setViewedFile3([]);
        setFile3([]);

        setLoading(false);
        toast.success(`Shared successfully`);
      })
      .catch((error) => {
        setLoading(false);
        handleErrors(error);
      });
  };

  return (
    <div>
      <div className={styles.latest}>
        <div className={styles.latestContent}>
          {/* <p className={styles.latestContentTitle}>latest shares</p> */}
        </div>
        <div className={styles.subHeaderLine}>
          <div className={styles.subHeaderLineContent}></div>
        </div>
      </div>

      <div className={styles.cards}>
        <div className={styles.card}>
          <div className={styles.header}>
            <img src={share1} />
            <h1>{t('photo_videos')}</h1>
          </div>
          <div className={styles.body} style={{ textAlign: "center" }}>
            <h2>{t('upload_photo_video_parag')}</h2>

            {viewedFile1.id ? (
              <div style={{ position: "relative" }}>
                {viewedFile1.path.includes("video") ? (
                  <video width="250" controls>
                    <source src={viewedFile1.path} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={viewedFile1.path} width="250" />
                )}

                <div style={{ position: "absolute", top: -5, right: -20 }}>
                  <Button
                    color={"danger"}
                    size="sm"
                    className="m-2"
                    variant="outline"
                    onClick={removeFile1}
                  >
                    x
                  </Button>
                </div>
              </div>
            ) : (
              <label className={styles.attachment}>
                <input
                  onChange={handleFile1}
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpeg, video/mp4"
                />
                <img alt="Image here" src={attach} />
              </label>
            )}

            <div className={styles.inputs}>
              <input
                type="text"
                id="name"
                // value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('your_name_here')}
              />
            </div>

            {viewedFile1.id && name && (
              <div style={{ textAlign: "center" }}>
                {loading ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    className={styles.bookButton}
                    onClick={() => share(1)}
                  >
                    {t('share_now')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.header}>
            <img src={share2} />
            <h1>{t('ideas_world')}</h1>
          </div>
          <div className={styles.body} style={{ textAlign: "center" }}>
            <h2 style={i18n.language ==="ar" ?{direction:'rtl'}:null}>{t('upload_ideas_parag')} </h2>

            {viewedFile2 ? (
              <div style={{ position: "relative" }}>
                {/* <img src={viewedFile2.path} width="250" /> */}
                <a target="_blank" rel="noopener noreferrer" href={viewedFile2}>
                  Check PDF
                </a>
                <div style={{ position: "absolute", top: -5, right: -20 }}>
                  <Button
                    color={"danger"}
                    size="sm"
                    className="m-2"
                    variant="outline"
                    onClick={removeFile2}
                  >
                    x
                  </Button>
                </div>
              </div>
            ) : (
              <label className={styles.attachment}>
                <input
                  onChange={handleFile2}
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept=".pdf"
                />
                <img alt="Image here" src={attach} />
              </label>
            )}

            <div className={styles.inputs}>
              <input
                type="text"
                id="name"
                // value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('your_name_here')}
              />
            </div>

            {viewedFile2 && name && (
              <div style={{ textAlign: "center" }}>
                {loading ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    className={styles.bookButton}
                    onClick={() => share(2)}
                  >
                    {t('share_now')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.header}>
            <img src={share2} />
            <h1>{t('other_word')}</h1>
          </div>
          <div className={styles.body} style={{ textAlign: "center" }}>
            <h2>{t('upload_videos_others_parag')}</h2>

            {viewedFile3.id ? (
              <div style={{ position: "relative" }}>
                <img src={viewedFile3.path} width="250" />
                <div style={{ position: "absolute", top: -5, right: -20 }}>
                  <Button
                    color={"danger"}
                    size="sm"
                    className="m-2"
                    variant="outline"
                    onClick={removeFile3}
                  >
                    x
                  </Button>
                </div>
              </div>
            ) : (
              <label className={styles.attachment}>
                <input
                  onChange={handleFile3}
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/*"
                />
                <img alt="Image here" src={attach} />
              </label>
            )}

            <div className={styles.inputs}>
              <input
                type="text"
                id="name"
                // value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('your_name_here')}
              />
            </div>

            {viewedFile3.id && name && (
              <div style={{ textAlign: "center" }}>
                {loading ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    className={styles.bookButton}
                    onClick={() => share(3)}
                  >
                    {t('share_now')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareMore;
