import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/Teams.module.css";
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

const TeamCard = (props) => {
    const [data, setData] = useState(props.data);
    const { t, i18n } = useTranslation();

    return (
        <>
            <Card>
                <CardActionArea>
                    <div style={{ width: '100%', display: 'table' }}>
                        <Grid container>
                            <Grid item xs={1} md={1}>
                                <div className={styles.headerPart1} style={{ backgroundColor: '#BF2A30' }}></div>
                            </Grid>
                            <Grid item xs={8} md={8}>
                                <div className={styles.headerPart2}>
                                    <div className={styles.headerPart2Content}>{
                                        i18n.language === "ar" ?
                                            data.data.arabic_name :
                                            data.data.name
                                    }</div>
                                </div>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <div className={styles.headerPart3}>
                                    <img
                                        // src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/countriesImages/${data.data.country_image}`}
                                        src={`https://www.flagdetective.com/images/download/lebanon-hi.jpg`}
                                        height={"70"}
                                        width={"90"}
                                        style={{ objectFit: 'cover' }}
                                        loading={"lazy"}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={styles.driversSection}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <div className={styles.driversSectionLeft}>
                                    <p>{
                                        props.language === "ar" ?
                                            data.driver1_firstname_arabic
                                            :
                                            data.driver1_firstname
                                    } {
                                            props.language === "ar" ?
                                                data.driver1_lastname_arabic
                                                :
                                                data.driver1_lastname
                                        }</p>
                                    <img
                                        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/driversImages/${data.driver1_image}`}
                                        // src={`https://s3-eu-west-1.amazonaws.com/racingnews-v2-prod/Riders/Leclerc/_570x570_crop_center-center_none/f1_2022_cl_fer_lg.png?v=1647865421`}
                                        style={{ objectFit: 'cover' }}
                                        loading={"lazy"}
                                        height={'300'}

                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={styles.driversSectionLeft}>
                                <p>{
                                        props.language === "ar" ?
                                            data.driver2_firstname_arabic
                                            :
                                            data.driver2_firstname
                                    } {
                                            props.language === "ar" ?
                                                data.driver2_lastname_arabic
                                                :
                                                data.driver2_lastname
                                        }</p>
                                    <img
                                        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/driversImages/${data.driver2_image}`}
                                        // src={`https://s3-eu-west-1.amazonaws.com/racingnews-v2-prod/Riders/Leclerc/_570x570_crop_center-center_none/f1_2022_cl_fer_lg.png?v=1647865421`}
                                        style={{ objectFit: 'cover' }}
                                        loading={"lazy"}
                                        height={'300'}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <CardContent style={{ height: '160px' }} className={styles.carSection}>
                        <img
                            src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/teamsImages/${data.data.car_image}`}
                            // src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWlnZbOo0aibm2GuaC4NsAje1JSVHtLmBerQ&usqp=CAU`}
                            style={{ objectFit: 'cover' }}
                            loading={"lazy"}
                        />
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    );
};

export default TeamCard;
