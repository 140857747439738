import React, { useState, useEffect } from "react";
import styles from "../../styles/new/TicketCard.module.css";
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import axios from "axios";
import { Box, Button, CircularProgress, FormControl, Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";
import SingleCustomAutocomplete from "../../components/CustomAutocomplete/SingleCustomAutocomplete";
import handleErrors from "../../helpers/handleErrors";
import Receipt from "./Receipt";
import SingleCustomAutocomplete2 from "../../components/CustomAutocomplete/SingleCustomAutocomplete2";

const Book = (props) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const [formData, setFormData] = useState({
        ticket_id: props.id,
        ticket_number: props.ticket_number,
        fname: "",
        lname: "",
        email: "",
        phone: "",
        country_id: null,
        city_id: null,
        address: "",
    });


    const resetForm = () => {
        setFormData({
            ticket_id: props.id,
            ticket_number: props.ticket_number,
            fname: "",
            lname: "",
            email: "",
            phone: "",
            country_id: null,
            city_id: null,
            address: "",
        })
    }
    useEffect(() => {
        const getCountries = () => {
            axios
                .get(
                    `${process.env.REACT_APP_API_BASE_URL}/masterData/countries/get`,
                    {
                    }
                )
                .then(function (response) {
                    setCountries(response.data);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        };
        if (countries.length == 0) {
            getCountries();
        }
    }, []);

    useEffect(() => {
        const getCities = () => {
            axios
                .get(
                    `${process.env.REACT_APP_API_BASE_URL}/masterData/cities/getByCountry/${formData.country_id}`,
                    {
                    }
                )
                .then(function (response) {
                    setCities(response.data);
                    setFormData({
                        ...formData,
                        city: null,
                    })
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        };
        if (formData.country_id > 0) {
            getCities();
        }
    }, [formData.country_id]);

    useEffect(() => {
        resetForm();
    }, [props.open]);


    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    const customOnChange = (newValue, name) => {
        setFormData((prev) => ({ ...prev, [name]: newValue }));
    };

    const BookTicket = () => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/tickets/book`,
            data: formData,
        })
            .then(response => {
                toast.success(`Booked successfully`);
                setSaveIsLoading(false);
                props.handleClose(response.data);
            })
            .catch(error => {
                setSaveIsLoading(false);
                handleErrors(error);
            });
    }
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                maxWidth='lg'
                fullWidth
            >
                <div style={{ width: '100%' }}>
                    <div>
                        <h3 style={i18n.language === "ar" ? { textTransform: 'uppercase', direction: 'rtl' } : { textTransform: "uppercase" }} className={styles.billingTitle}>{t('billing_address_word')}</h3>
                        <Grid container spacing={2} style={i18n.language === "ar" ? { direction: 'rtl' } : null}>
                            <Grid item xs={12} md={6} direction="column" container>
                                <FormControl style={{ width: '100%' }}>
                                    <TextField
                                        style={{ margin: "20px" }}
                                        label={t('first_name_word')}
                                        variant="outlined"
                                        id="fname"
                                        value={formData.fname}
                                        onChange={handleChange}
                                    /></FormControl>
                                <TextField
                                    style={{ margin: "20px" }}
                                    label={t('last_name_word')}
                                    variant="outlined"
                                    id="lname"
                                    value={formData.lname}
                                    onChange={handleChange}
                                />
                                <TextField
                                    style={{ margin: "20px" }}
                                    label={t('email_word')}
                                    variant="outlined"
                                    id="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <TextField
                                    style={{ margin: "20px" }}
                                    label={t('phone_word')}
                                    variant="outlined"
                                    id="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} direction="column" container>
                                <TextField
                                    style={{ margin: "20px" }}
                                    label={t('address_word')}
                                    variant="outlined"
                                    id="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                />
                                <div style={{ margin: '20px' }}>
                                    <Grid item xs={12} direction="row" container>
                                        <SingleCustomAutocomplete2
                                            filedName="country_id"
                                            label={t('country_word')}
                                            list={countries}
                                            value={formData.country_id}
                                            listKey="id"
                                            description="name"
                                            customOnChange={customOnChange}
                                        />
                                    </Grid>
                                </div>
                                <div style={{ margin: '20px' }}>
                                    <Grid item xs={12} direction="row" container>
                                        <SingleCustomAutocomplete2
                                            filedName="city_id"
                                            label={t('city_word')}
                                            list={cities}
                                            value={formData.city_id}
                                            listKey="id"
                                            description="name"
                                            customOnChange={customOnChange}
                                        />
                                    </Grid>
                                </div>
                            </Grid>
                            <Button
                                className={styles.orderBtn}
                                variant="outlined"
                                onClick={BookTicket}
                            >
                                <h3>
                                    {
                                        saveIsLoading ? <CircularProgress />
                                            :
                                            t('place_order_word')
                                    }
                                </h3>
                            </Button>
                        </Grid>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Book;
