import React from "react";
import styles from "../../styles/Pages.module.css";
import { useTranslation } from "react-i18next";

const HeroSection = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.HomeHeroSection}>
      {/* <h1 className={styles.HomeHeroTitle}>to all over the world</h1>
      <h2 className={styles.HomeHeroSubTitle}>
        create, produce and publish original content focused on Formula1 sector
        and motorsports industry.
      </h2> */}
      <div
        dangerouslySetInnerHTML={{
          __html:
            i18n.language === "ar" ?
              props.data.arabic_text1
              :
              props.data.text1
        }}
      ></div>
    </div>
  );
};

export default HeroSection;
