import React from "react";
import styles from "../../styles/Pages.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WhoSection = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.WhoSectionBig}>
      <div className={styles.WhoSection}>
        <div className={styles.WhoContent}>
          <h2 className={i18n.language === "ar" ? styles.WhoTitleAr : styles.WhoTitle}>{t('welcome_word')}</h2>
          <h1 className={i18n.language === "ar" ? styles.WhoSubTitleAr : styles.WhoSubTitle}>{t('who_we_are_word')}</h1>

          {/* <p className={styles.WhoParag}>
          A Saudi registered company working to create, produce and publish
          original multilingual (Arabic, English, French) content focused on
          Formula1 sector and motorsports industry
          </p> */}
          <div
            className={i18n.language === "ar" ? styles.WhoParagAr : styles.WhoParag}
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === "ar" ?
                  props.data.arabic_text2
                  :
                  props.data.text2
            }}
          ></div>
          <button className={i18n.language === "ar" ? styles.WhoBtnAr : styles.WhoBtn}>
            <Link
              to={`/about-us`}
              style={{ textDecoration: 'none', color: 'white' }}
            >{t('learn_more_word')}</Link></button>
        </div>
      </div>
    </div>
  );
};

export default WhoSection;
