import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/Drivers.module.css";
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import axios from "axios";

const Details = (props) => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);

    useEffect(() => {
        const getDrivers = () => {
            axios
                .get(
                    `${process.env.REACT_APP_API_BASE_URL}/masterData/drivers/getBySeasonDetails`,
                    {
                        params: {
                            season_id: props.season_id,
                            id: props.id,
                        },
                    }
                )
                .then(function (response) {
                    setData(response.data);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        };
        getDrivers();
    }, [props.open]);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                maxWidth='lg'
                fullWidth
            >
                <div style={{ width: '100%' }}>
                    <div className={styles.detailsHeader}>
                        <div className={styles.detailsHeaderImg}>
                            <img
                                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/driversImages/${data.profile_wallpaper}`}
                                // src={`https://pbs.twimg.com/media/FcTXwpsX0AQcqQl.jpg:large`}
                                height={"650"}
                                width={"400"}
                                loading={"lazy"}
                            />
                        </div>
                        <div className={styles.detailsHeaderRight1}>
                            <div className={styles.detailsFlag}>
                                <img
                                    src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/countriesImages/${data.country_image}`}
                                    // src={`https://www.flagdetective.com/images/download/lebanon-hi.jpg`}
                                    height={"45"}
                                    width={"60"}
                                    style={{ border: '1px solid black', objectFit: 'cover' }}
                                    loading={"lazy"}
                                />
                            </div>
                            <div className={styles.detailsHeaderRight1Details}>
                                <div className={styles.detailsHeaderRight1Driver}>
                                    <p>
                                        <span>{
                                            i18n.language === 'ar' ?
                                                data.arabic_first_name
                                                :
                                                data.first_name

                                        } </span>
                                        {
                                            i18n.language === 'ar' ?
                                                data.arabic_last_name
                                                :
                                                data.last_name
                                        }
                                    </p>
                                </div>
                                <div className={styles.detailsHeaderRight1Points}>
                                    <p>{t('driver_points')} {data.total_points}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.detailsHeaderRight2}>
                            <div className={styles.detailsHeaderRight2Content}>
                                <table className={styles.tableRight} style={i18n.language === "ar" ? { direction: "rtl", textAlign: "right" } : null}>
                                    <tbody>
                                        <tr>
                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                {t('team_word')}
                                            </th>
                                            <th width="50%" align="right" className={styles.rightTH}>
                                                {
                                                    i18n.language === "ar" ?
                                                        data.arabic_team_name
                                                        :
                                                        data.team_name
                                                }
                                                {/* Ferrari */}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                {t('country_word')}
                                            </th>
                                            <th width="50%" align="right" className={styles.rightTH}>
                                                {
                                                    i18n.language === "ar" ?
                                                        data.arabic_country_name
                                                        :
                                                        data.country_name
                                                }
                                                {/* Country */}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                {t('podium_word')}
                                            </th>
                                            <th width="50%" align="right" className={styles.rightTH}>
                                                {
                                                    data.podium
                                                }
                                                10
                                            </th>
                                        </tr>
                                        <tr>
                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                {t('points_word')}
                                            </th>
                                            <th width="50%" align="right" className={styles.rightTH}>
                                                {
                                                    data.total_points
                                                }
                                                10
                                            </th>
                                        </tr>
                                        <tr>
                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                {t('grand_prix_word')}
                                            </th>
                                            <th width="50%" align="right" className={styles.rightTH}>
                                                {
                                                    data.grand_prix_entered
                                                }
                                                {/* 10 */}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                {t('championship_word')}
                                            </th>
                                            <th width="50%" align="right" className={styles.rightTH}>
                                                {
                                                    data.world_championships
                                                }
                                                {/* 10 */}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                {t('highest_race_word')}
                                            </th>
                                            <th width="50%" align="right" className={styles.rightTH}>
                                                {
                                                    data.highest_race_finish
                                                }
                                                10
                                            </th>
                                        </tr>
                                        <tr>
                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                {t('highestgrid_word')}
                                            </th>
                                            <th width="50%" align="right" className={styles.rightTH}>
                                                {
                                                    data.highest_grid_position
                                                }
                                                1
                                            </th>
                                        </tr>
                                        <tr>
                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                {t('date_of_birth_word')}
                                            </th>
                                            <th width="50%" align="right" className={styles.rightTH}>
                                                {data.date_of_birth}
                                                {/* 2022-10-10 */}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th width="50%" align={i18n.language === "ar" ? 'right' : 'left'} className={styles.leftTH}>
                                                {t('place_of_birth_word')}
                                            </th>
                                            <th width="50%" align="right" className={styles.rightTH}>
                                                {data.place_of_birth}
                                                {/* Lebanon */}
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div className={styles.biographyTitle} style={i18n.language ==="ar" ? {textAlign:'right',direction:'rtl'} : null}>
                            <div className={styles.biographyContent}>
                                {t('biography_word')}
                            </div>
                            <div className={styles.biographyBody}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            i18n.language === "ar" ?
                                                data.arabic_description
                                                :
                                                data.description
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Details;
