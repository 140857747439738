import React, { useState, useEffect } from "react";
import styles from "../../styles/Pages.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import WatchVideo from "./WatchVideo";
import { useTranslation } from "react-i18next";

const LatestVideos = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState('');
  const { t, i18n } = useTranslation();

  const getData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/videos/getTopVideos`, {
        params: {},
      })
      .then(function (response) {
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error.response);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);


  const updateFrom = id => {
    setId(id);
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div className={styles.latestVideos}>
      <h1 className={styles.latestVideosTitle}>{t('latest_videos_word')}</h1>
      <div className={styles.latestVideosBtns}>
        {
          loading ? <CircularProgress />
            :
            data.map((e, index) => (
              <button className={styles.latestVideosBtn}
                onClick={() => {
                  updateFrom(e.id);
                }}
                key={index}>{e.title}</button>
            ))
        }
      </div>
      <button className={styles.watchMoreBtn}>
        <Link
          to={`/MenaTv`}
          style={{ textDecoration: 'none', color: 'white' }}
        > {t('watch_more')}</Link>
      </button>
      <WatchVideo
        open={visible}
        id={id}
        handleClose={handleClose}
      />
    </div>
  );
};

export default LatestVideos;
