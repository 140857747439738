import React from 'react';
import { toast } from 'react-toastify';

const handleErrors = error => {
  if (error.response) {
    if (error.response.status === 400) {
      if (typeof error.response.data == 'object') {
        toast.error(Object.values(error.response.data)[0][0]);
      } else {
        toast.error(error.response.data[0]);
      }
    } else if (error.response.status === 413) {
      toast.error('Too Large');
    } else if (error.response.status === 422) {
      toast.error(
        <div>
          <span className={'error-list-title'}>Validation Error</span>
          <ul className={'error-list'}>
            {Object.values(error.response.data).map((val, index) => {
              return (
                <li className={'error-list-item'} key={index}>
                  {val}
                </li>
              );
            })}
          </ul>
        </div>,
        { enableHtml: true },
      );
    } else if (error.response.status === 401) {
      toast.error('Expired Please Login Again');
    } else {
      toast.error('Unexpected Error 🙁');
    }
    // Request made and server responded
  } else if (error.request) {
    // The request was made but no response was received
    toast.error("No Response From Our Server (We'll Fix It Soon) 🙁");
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error('Could Not Esablish Request');
    console.log('Error', error.message);
  }
};
export default handleErrors;
