import { Container } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ArticleHero from "../components/NewsArticles/ArticleHero";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

const Article = (props) => {
  const [article, setArticle] = useState("");
  let id = 1;
  const { article_id } = useParams();

  const { t, i18n } = useTranslation();
  const [language, setlanguage] = useState(i18n.language);

  const getArticle = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/article/get`, {
        params: {
          // id: window?.location?.pathname
          //   ?.split("news-articles/")[1]
          //   ?.split("/")[1],
          id: article_id,
        },
      })
      .then(function (response) {
        setArticle(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getArticle();
  }, []);

  return (
    <div>
      <ArticleHero
        imgSrc={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/newsImages/${article.image}`}
      />

      <Container maxWidth={'lg'}>
        <h2 style={i18n.language === "ar" ? { direction: "rtl", textAlign: "right" } : null}>{
          i18n.language === "ar" ?
            article.arabic_title
            :
            article.title}</h2>
        <div  style={i18n.language === "ar" ? { direction: 'rtl'} : { textAlign:'center'}} >
          <div dangerouslySetInnerHTML={{
            __html:
              i18n.language === "ar" ?
                article.arabic_description
                :
                article.description
          }} className="ck-wrap"></div>
        </div>
        <div style={i18n.language === "ar" ? { direction: "rtl", textAlign: "right" } : { textAlign: "right" }}>
          <i>{article.date}</i>
          <p>{
            i18n.language === "ar" ?
              article.author_arabic_name
              :
              article.author_name
          }</p>
        </div>
      </Container>
    </div>
  );
};

export default Article;
