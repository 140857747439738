import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/system";

import { remove } from "../features/basket/basketSlice";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import styles from "../styles/Checkout.module.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { empty, increase } from "../features/basket/basketSlice";
import { useTranslation } from "react-i18next";
import handleErrors from "../helpers/handleErrors";
import SingleCustomAutocomplete2 from "../components/CustomAutocomplete/SingleCustomAutocomplete2";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

const Checkout = () => {
  const items = useSelector((state) => state.basket.items);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [message, setMessage] = useState(false);

  const [ordering, setOrdering] = useState(false);
  const [form, setForm] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    country: null,
    city: null,
    address: "",
  });
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    let total = 0;

    items.map(
      (item) =>
      (total += item.discounted_price
        ? item.discounted_price * item.buy
        : item.selling_price * item.buy)
    );

    setTotalPrice(total);
  }, [items]);

  useEffect(() => {
    const getCountries = () => {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/masterData/countries/get`,
          {}
        )
        .then(function (response) {
          setCountries(response.data);
        })
        .catch(function (error) {
          console.log(error.response);
        });
    };
    getCountries();
  }, []);

  useEffect(() => {
    const getCities = () => {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/masterData/cities/getByCountry/${form.country}`,
          {}
        )
        .then(function (response) {
          setCities(response.data);
          setForm({
            ...form,
            city: null,
          });
        })
        .catch(function (error) {
          console.log(error.response);
        });
    };
    if (form.country > 0) {
      getCities();
    }
  }, [form.country]);

  const handleChange = (e) => {
    const { id, value } = e.target;

    setForm((prev) => ({ ...prev, [id]: value }));
  };

  const order = () => {
    setOrdering(true);

    let orderItems = items.map((item) => ({
      id: item.id,
      name: item.name,
      buy: item.buy,
      size: item.size,
      colorName: item.colorName,
      cost_price: item.cost_price,
      discounted_price: item.discounted_price,
      selling_price: item.selling_price,
      code: item.code,
    }));

    const itemsValue = JSON.stringify(orderItems);

    const data = new FormData();

    data.append("items", itemsValue);

    data.append("user_fname", form.fname);
    data.append("user_lname", form.lname);
    data.append("user_email", form.email);
    data.append("user_phone", form.phone);
    data.append("user_country", form.country);
    data.append("user_city", form.city);
    data.append("user_address", form.address);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/user/order`,
      data: data,
    })
      .then((res) => {
        toast.success(t("order_placed"));
        setOrdering(false);
        setMessage(true);
        dispatch(empty());
        setForm((prev) => ({
          fname: "",
          lname: "",
          email: "",
          phone: "",
          country: null,
          city: null,
          address: "",
        }));
      })
      .catch((err) => {
        // if(err.response.status === 400){
        // console.log( typeof err.response.data);
        toast.error(t("quantity_error"));
        // }else{
        // handleErrors(err);
        // }
        // toast.error("Order not placed, please try again");
        // toast.error(err);
        setOrdering(false);
      });
  };

  const customOnChange = (newValue, name) => {
    setForm((prev) => ({ ...prev, [name]: newValue }));
  };
  return (
    <div style={{ paddingTop: "100px", paddingBottom: "100px" }}>
      {
        message ?
          <Container>
            <Grid container
              justifyContent="center"
              alignItems="center"
              alignContent="center">
              <Grid item xs={12} md={12}>
                <div className={styles.messageBox}>
                  <p>Your order has been placed.Please check your email for more information.</p>
                  <Link
                    to={`/Home`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      className={styles.homeButton}
                      variant="outline"
                    >
                      {t('home')}
                    </Button>
                  </Link>
                </div>

              </Grid>
            </Grid>
          </Container>
          :
          <Container>
            <TableContainer
              component={Paper}
              style={i18n.language === "ar" ? { direction: "rtl" } : null}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#544F50",
                      textTransform: "uppercase",
                    }}
                  >
                    <TableCell
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      {t("order_summary_word")}
                    </TableCell>
                    <TableCell
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      {t("price_word")}
                    </TableCell>
                    <TableCell
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      {t("quantity_word")}
                    </TableCell>

                    <TableCell
                      style={{ color: "white", fontWeight: "bold" }}
                      align="center"
                    >
                      {t("total_word")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div style={{ display: "flex" }}>
                          <img
                            width={"200px"}
                            src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/itemsImages/${item.image}`}
                            style={{ marginRight: "10px" }}
                          />
                          <div style={{ paddingLeft: "10px" }}>
                            <p>
                              {" "}
                              {i18n.language === "ar"
                                ? item.arabic_name
                                : item.name}
                            </p>
                            <p>
                              {t("size_word")}: {item.size}
                            </p>
                            <p>
                              {t("color_word")}: {item.colorName}
                            </p>
                          </div>
                        </div>
                      </TableCell>

                      <TableCell align="center">
                        ${" "}
                        {item.discounted_price
                          ? item.discounted_price
                          : item.selling_price}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: "#BF2A30",
                          fontWeight: "bold",
                        }}
                      >
                        <span style={{ backgroundColor: "#D9D9D9", padding: 5 }}>
                          {item.buy}
                        </span>
                        <button
                          onClick={() => dispatch(increase(item))}
                          style={{
                            border: 0,
                            marginLeft: 5,
                            backgroundColor: "transparent",
                            color: "#BF2A30",
                            cursor: "pointer",
                          }}
                        >
                          +
                        </button>
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{ color: "#BF2A30", fontWeight: "bold" }}
                      >
                        ${" "}
                        {item.discounted_price
                          ? item.discounted_price * item.buy
                          : item.selling_price * item.buy}
                        <button
                          onClick={() => dispatch(remove(item))}
                          style={{
                            border: 0,
                            marginLeft: 10,
                            backgroundColor: "transparent",
                            color: "#544F50",
                            cursor: "pointer",
                          }}
                        >
                          x
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">
                      <h3>{t("total_items_word")} </h3>
                    </TableCell>
                    <TableCell align="center">
                      <h3>${totalPrice}</h3>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {items.length > 0 && (
              <div>
                <h3
                  style={
                    i18n.language === "ar"
                      ? { textTransform: "uppercase", direction: "rtl" }
                      : { textTransform: "uppercase" }
                  }
                >
                  {t("billing_address_word")}
                </h3>
                <Grid
                  container
                  spacing={2}
                  style={i18n.language === "ar" ? { direction: "rtl" } : null}
                >
                  <Grid item xs={12} md={6} direction="column" container>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        style={{ margin: "20px" }}
                        label={t("first_name_word")}
                        variant="outlined"
                        id="fname"
                        value={form.fname}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <TextField
                      style={{ margin: "20px" }}
                      label={t("last_name_word")}
                      variant="outlined"
                      id="lname"
                      value={form.lname}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{ margin: "20px" }}
                      label={t("email_word")}
                      variant="outlined"
                      id="email"
                      value={form.email}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{ margin: "20px" }}
                      label={t("phone_word")}
                      variant="outlined"
                      id="phone"
                      value={form.phone}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} direction="column" container>
                    <TextField
                      style={{ margin: "20px" }}
                      label={t("address_word")}
                      variant="outlined"
                      id="address"
                      value={form.address}
                      onChange={handleChange}
                    />
                    <div style={{ margin: "20px" }}>
                      <Grid item xs={12} md={12} direction="row" container>
                        <SingleCustomAutocomplete2
                          filedName="country"
                          label={t("country_word")}
                          list={countries}
                          value={form.country}
                          listKey="id"
                          description="name"
                          customOnChange={customOnChange}
                        />
                      </Grid>
                    </div>
                    <div style={{ margin: "20px" }}>
                      <Grid item xs={12} md={12} direction="row" container>
                        <SingleCustomAutocomplete2
                          filedName="city"
                          label={t("city_word")}
                          list={cities}
                          value={form.city}
                          listKey="id"
                          description="name"
                          customOnChange={customOnChange}
                        />
                      </Grid>
                    </div>
                  </Grid>
                  {ordering ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <button
                      disabled={Object.values(form).some((value) => value == "")}
                      className={styles.orderBtn}
                      onClick={order}
                    >
                      <h3>{t("place_order_word")}</h3>
                    </button>
                  )}
                </Grid>
              </div>
            )}
          </Container>
      }


    </div>
  );
};

export default Checkout;
