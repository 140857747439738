import axios from "axios";
import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import video from "../assets/videos/racing.mp4";
import styles from "../styles/Pages.module.css";
import { CircularProgress } from "@mui/material";
import NewsBrief from "../components/Home/NewsBrief";
import HeroSection from "../components/Home/HeroSection";
import WhoSection from "../components/Home/WhoSection";
import LatestVideos from "../components/Home/LatestVideos";
import ArticlesBrief from "../components/Home/ArticlesBrief";

const Home = (props) => {
  const [categories, setCategories] = useState([]);
  const [news, setNews] = useState([]);
  const [pageData, setPageData] = useState({});
  const [btnTextStyle, setBtnTextStyle] = useState({});
  const [videoSource, setVideoSource] = useState("");
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  const videoRef = createRef();

  const [width, setWidth] = useState(100);

  const handleVideo = (event) => {
    let currentTime = event.target.currentTime;
    let duration = event.target.duration;

    console.log("currentTime", currentTime);
    console.log("duration", duration);
    let perc = 100 - (currentTime * 100) / duration;
    setWidth(perc);
  };

  const getFonts = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/fonts/get`, {})
      .then(function (response) {
        const style = response.data.filter(
          (data) => data.element == "btnText"
        )[0];

        if (style) {
          setBtnTextStyle((prev) => ({
            fontFamily: style.family,
            fontSize: style.size + "px",
            fontWeight: style.weight,
          }));
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getNews = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/news/getTopNews`, {})
      .then(function (response) {
        setNews(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/categories/get`, {})
      .then(function (response) {
        setCategories(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  const getPageData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "Home",
        },
      })
      .then(function (response) {
        const src = `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.video}`;
        setVideoSource((prev) => src);
        setPageData((prev) => response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error.response);
        setLoading(false);
      });
  };
  useEffect(() => {
    getCategories();
    getFonts();
    getNews();
    getPageData();

  }, []);

  return (
    <div className={styles.main}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <HeroSection data={pageData} />

          <NewsBrief data={news} />

          <WhoSection data={pageData} />

          <LatestVideos />

          <ArticlesBrief data={pageData}/>
        </>
      )}
    </div>
  );
};

export default Home;
