import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/Drivers.module.css";
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

const DriverCard = (props) => {
    const { t, i18n } = useTranslation();
    const data = props.data;


    return (
        <>
            {/* <div className={styles.styleCard}>
                
            </div> */}
            <Card sx={{ maxWidth: 500 }} >
                <CardActionArea>
                    <div style={{ width: '100%' }}>
                        <div className={styles.driverCardNbr}> {data.driver_number}</div>
                        <div className={styles.driverCardFlag}>
                            <img
                                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/countriesImages/${data.country_image}`}
                                // src={`https://www.flagdetective.com/images/download/lebanon-hi.jpg`}
                                height={"45"}
                                width={"60"}
                                style={{ border: '1px solid black', objectFit: 'cover' }}
                                loading={"lazy"}
                            />
                        </div>
                    </div>
                    <CardMedia
                        component="img"
                        height="300"
                        // image={'https://s3-eu-west-1.amazonaws.com/racingnews-v2-prod/Riders/Leclerc/_570x570_crop_center-center_none/f1_2022_cl_fer_lg.png?v=1647865421'}
                        image={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/driversImages/${data.driver_image}`}
                        alt="driver"
                        style={{ objectFit: 'contain' }}
                    />
                    <CardContent style={{ backgroundColor: '#544F50', height: '140px' }} className={styles.driverCardDriverName}>
                        <p>
                            <span>{
                                i18n.language === 'ar' ?
                                    data.arabic_first_name
                                    :
                                    data.first_name

                            } </span>
                            {
                                i18n.language === 'ar' ?
                                    data.arabic_last_name
                                    :
                                    data.last_name
                            }
                        </p>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    );
};

export default DriverCard;
