import React, { useState, useEffect, createRef } from "react";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Grid from "@mui/material/Grid";
import styles from "../../styles/new/ItemDetails.module.css";
import SingleCustomAutocomplete from "../../components/CustomAutocomplete/SingleCustomAutocomplete";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from "react-slick";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { add, remove, empty } from "../../features/basket/basketSlice";
import { useParams } from "react-router-dom";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={styles.ArrowForward}
            onClick={onClick}
        >
            <ArrowForwardIcon sx={{ fontSize: "3vw" }} />
        </button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={styles.ArrowBack}
            onClick={onClick}
        >
            <ArrowBackIcon sx={{ fontSize: "3vw" }} />
        </button>
    );
}


const ItemDetails = () => {
    const items = useSelector((state) => state.basket.items);
    const dispatch = useDispatch();
    const { item } = useParams();
    const [data, setData] = useState([]);

    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState({});
    const [sizes, setSizes] = useState([]);
    const [colors, setColors] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [size, setSize] = useState("");
    const [color, setColor] = useState({});

    // const sliderRef = createRef();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const addToBasket = () => {
        if (!size || !color)
            return toast.warning(t('please_choose_color_word'));
        dispatch(
            add({
                ...data,
                buy: quantity,
                size: size,
                colorName: color.colorName,
                color1: color.color1,
                color2: color.color2,
            })
        );

        toast.success(t('item_added'));
    };

    const getItem = () => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/data/item/get`, {
                params: {
                    item_id: item,
                },
            })
            .then(function (response) {
                let data = response.data;
                setData(response.data);
                if (data.sizes) setSizes(JSON.parse(data.sizes));
                if (data.colors) setColors(JSON.parse(data.colors));
                setLoading(false);

            })
            .catch(function (error) {
                console.log(error.response);
                setLoading(false);

            });
    };

    useEffect(() => {
        getItem();
    }, [item]);

    // useEffect(() => {
    //     console.log("enter"+data.length);
    //     if (data.length > 0) {
    //         console.log("enter2");
    //         if (data && data.sizes) setSizes(JSON.parse(data.sizes));
    //         if (data && data.colors) setColors(JSON.parse(data.colors));
    //     }
    // }, [data]);

    return (
        <>
            {
                loading ? <CircularProgress />
                    :
                    <>
                        <Grid container style={{ backgroundColor: '#F0F0F0' }}>
                            <div className={styles.imageSlider}>
                                <Slider {...settings} className={styles.slider}>
                                    <div className={styles.sliderContent}>
                                        <img
                                            src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/itemsImages/${data.image}`}
                                            // src={`https://s3-eu-west-1.amazonaws.com/racingnews-v2-prod/Riders/Leclerc/_570x570_crop_center-center_none/f1_2022_cl_fer_lg.png?v=1647865421`}
                                            width={"500"}
                                            loading={"lazy"}
                                            style={{ objectFit: 'cover' }}
                                        />
                                    </div>
                                    {
                                        data.images?.length > 0 ?
                                            data.images.map((e, index) => (
                                                <div className={styles.sliderContent} key={index}>
                                                    <img
                                                        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/itemsImages/${e.name}`}
                                                        // src={`https://s3-eu-west-1.amazonaws.com/racingnews-v2-prod/Riders/Leclerc/_570x570_crop_center-center_none/f1_2022_cl_fer_lg.png?v=1647865421`}
                                                        width={"500"}
                                                        loading={"lazy"}
                                                        style={{ objectFit: 'cover' }}
                                                    />
                                                </div>
                                            ))
                                            : ''
                                    }
                                </Slider>
                            </div>
                            <div className={styles.imageContainer}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} lg={3} style={{ textAlign: 'center' }}>
                                        <div style={{ backgroundColor: 'white', height: '350px' }}><img
                                            src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/itemsImages/${data.image}`}
                                            width={"300"}
                                            loading={"lazy"}
                                            style={{ objectFit: 'cover' }}
                                        /></div>
                                    </Grid>
                                    {
                                        data.images.length > 0 ?
                                            data.images.map((e, index) => (
                                                <Grid item xs={12} md={2} lg={3} style={{ textAlign: 'center' }}>
                                                    <div style={{ backgroundColor: 'white', height: '350px' }}><img
                                                        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/itemsImages/${e.name}`}
                                                        width={"300"}
                                                        loading={"lazy"}
                                                    /></div>
                                                </Grid>
                                            ))
                                            : ''
                                    }
                                </Grid>
                            </div>
                            <div className={styles.bodySection} style={i18n.language === "ar" ? { direction: "rtl"} : null}>
                                <h3> {
                                    i18n.language == "ar" ? data.arabic_name : data.name
                                }</h3>
                                <p>
                                    <span style={{ textDecoration: 'line-through' }}>{data.discounted_price ? "$" + data.discounted_price + " " : ""}</span>
                                    <span>${data.selling_price}</span>
                                </p>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} md={2}>
                                        <h3>{t('color_word')}</h3>
                                    </Grid>
                                    {
                                        colors.map((obj) => (
                                            <Grid item xs={2} md={1}>
                                                <div className={
                                                    color.id == obj.id
                                                        ? styles.colorCaseSelected
                                                        :
                                                        styles.colorCase
                                                } style={{ backgroundColor: `${obj.color1}` }}
                                                    onClick={() => setColor(obj)}
                                                ></div>
                                            </Grid>
                                        ))
                                    }


                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={4} md={2}>
                                        <h3>{t('size_word')}</h3>
                                    </Grid>
                                    {
                                        sizes.map((obj) => (
                                            <Grid item xs={1} md={1}>
                                                <div className={
                                                    size == obj.size
                                                        ? styles.sizeCaseSelected
                                                        : 
                                                    styles.sizeCase
                                                }
                                                    onClick={() => {setSize(obj.size);console.log(obj);}}>
                                                    <div>{obj.size}</div>
                                                </div>
                                            </Grid>
                                        ))
                                    }


                                </Grid>
                                <Grid container className={styles.actions} spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <div className={styles.counter}>
                                            <button
                                                onClick={() => quantity > 1 && setQuantity((prev) => prev - 1)}
                                            >
                                                -
                                            </button>
                                            <p>{quantity}</p>
                                            <button onClick={() => setQuantity((prev) => prev + 1)}>+</button>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div className={styles.add}>
                                            <button onClick={addToBasket}>{t('add_to_basket_word')}</button>
                                        </div>
                                    </Grid>

                                </Grid>
                            </div>


                        </Grid>
                    </>
            }
        </>
    );
};

export default ItemDetails;
