import React, { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import "./App.css";

import Home from "./pages/Home";
import NewsArticles from "./pages/NewsArticles";
import Article from "./pages/Article";
import Group from "./pages/Group";
import AboutUs from "./pages/AboutUs";
import Store from "./pages/Store";
import NewStore from "./pages/Store/NewStore";
import Items from "./pages/Items";
import Drivers from "./pages/Drivers/Drivers";
import Teams from "./pages/Teams/Teams";
import Schedules from "./pages/Schedules/Schedules";
import Layout from "./components/Layout";
import Checkout from "./pages/Checkout";
import DriverCardDetails from "./pages/Drivers/DriverCardDetails";
import TeamCardDetails from "./pages/Teams/TeamCardDetails";
import Standings from "./pages/Standings";
import SheduleCardDetails from "./pages/Schedules/SheduleCardDetails";
import ScheduleCircuit from "./pages/Schedules/ScheduleCircuit";
import { useTranslation } from "react-i18next";
import ItemDetails from "./pages/Store/ItemDetails";
import MenaTv from "./pages/MenaTv/MenaTv";
import Tickets from "./pages/Tickets/Tickets";
import ContactUs from "./pages/ContactUs";
import ShareWithUs from "./pages/ShareWithUs";

function App() {
  const { t, i18n } = useTranslation();
  const [language, setlanguage] = useState(i18n.language);

  const handleChangeLanguage = (e) => {
    setlanguage(e);
  };

  return (
    <Layout language={language} handleChangeLanguage={handleChangeLanguage}>
      <Routes>
        <Route
          path="/Home"
          element={
            <Home
              language={language}
              handleChangeLanguage={handleChangeLanguage}
            />
          }
        />
        <Route path="/news" element={<NewsArticles language={language} />} />
        <Route
          path="/news-articles/:category_id"
          element={<NewsArticles language={language} />}
        />
        <Route path="/Group" element={<Group />} language={language} />
        <Route path="/About-us" element={<AboutUs language={language} />} />
        {/* <Route path="/Store" element={<Store />} language={language}/> */}
        <Route path="/Store" element={<NewStore />} language={language} />

        {/* <Route path="/store-items/:item" element={<Items language={language}/>} /> */}
        <Route
          path="/store-items/:item"
          element={<ItemDetails language={language} />}
        />

        <Route
          path="/:category_id/Drivers"
          element={<Drivers language={language} />}
        />
        <Route path="/Drivers" element={<Drivers language={language} />} />
        <Route
          path="/:category_id/Drivers/:season_id&:id"
          element={<DriverCardDetails language={language} />}
        />
        {/* <Route path="/:category_id/Teams" element={<Teams language={language}/>} /> */}
        <Route path="/Teams" element={<Teams language={language} />} />
        <Route
          path="/:category_id/Teams/:season_id&:id"
          element={<TeamCardDetails language={language} />}
        />
        <Route
          path="/:category_id/Schedules"
          element={<Schedules language={language} />}
        />
        <Route path="/Schedules" element={<Schedules language={language} />} />
        {/* <Route path="/:category_id/Schedules/:season_id&:id" element={<SheduleCardDetails language={language}/>} /> */}

        <Route
          path="/scheduleDetails/:season_id&:id"
          element={<SheduleCardDetails language={language} />}
        />
        <Route
          path="/scheduleCircuit/:season_id&:id"
          element={<ScheduleCircuit language={language} />}
        />

        {/* <Route path="/:category_id/Standings" element={<Standings language={language}/>} /> */}

        <Route path="/Standings" element={<Standings language={language} />} />
        <Route path="/MenaTv" element={<MenaTv language={language} />} />
        <Route path="/Tickets" element={<Tickets />} />
        <Route path="/Contact-us" element={<ContactUs />} />

        <Route
          path="/:category_name/news-articles/:category"
          element={<NewsArticles language={language} />}
        />
        <Route
          path="/:category_name/news-articles/:category/:article_id"
          element={<Article />}
          language={language}
        />
        <Route
          path="/news-articles/:article_id"
          element={<NewsArticles language={language} />}
        />
        <Route path="/Checkout" element={<Checkout />} />

        <Route path="/ShareWithUs" element={<ShareWithUs />} />
        <Route
          path="/"
          element={
            <Home
              language={language}
              handleChangeLanguage={handleChangeLanguage}
            />
          }
        />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
}

export default App;
