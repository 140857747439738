import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import styles from "../../styles/new/Schedules.module.css";
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

const SheduleCard = (props) => {
    const [data, setData] = useState(props.data);
    const { t, i18n } = useTranslation();

    return (
        <>
            <Card style={{ width: '100%' }}>
                <CardActionArea>
                    <div style={{ width: '100%', display: 'table' }}>
                        <Grid container>
                            <Grid item xs={3} md={3}>
                                <div className={styles.headerPart1} style={{ backgroundColor: '#BF2A30' }}>
                                    <div className={styles.headerPart1Content}>
                                        <span>{data.start_date}-{data.end_date}</span>
                                        <div>{data.month}</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <div className={styles.headerPart2}>
                                    <div className={styles.headerPart2Content}>
                                        <div>
                                            {
                                                i18n.language === "ar" ?
                                                    data.country_arabic_name
                                                    :
                                                    data.country_name
                                            }
                                        </div>
                                        <div>{
                                            i18n.language === "ar" ?
                                                data.track_arabic_name
                                                :
                                                data.track_name
                                        }</div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={3} md={3}>
                                <div className={styles.headerPart3}>
                                    <img
                                        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/countriesImages/${data.country_image}`}
                                        // src={`https://www.flagdetective.com/images/download/lebanon-hi.jpg`}
                                        height={"70"}
                                        width={"90"}
                                        style={{ objectFit: 'cover' }}
                                        loading={"lazy"}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={styles.imageSection}>
                        <img
                                src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/trackImages/${data.track_image}`}
                            // src={`https://www.autocar.co.uk/sites/autocar.co.uk/files/images/car-reviews/first-drives/legacy/80-f1-2021-season-circuit-guide-brazil.jpg`}
                            style={{ objectFit: 'cover' }}
                            width={'500px'}
                            loading={"lazy"}
                        />
                    </div>
                </CardActionArea>
            </Card>
        </>
    );
};

export default SheduleCard;
