import React from "react";

const ArticleHero = ({ imgSrc }) => {
  return (
    <div style={{ paddingTop: "50px" }}>
      <img width="100%" src={imgSrc}></img>
    </div>
  );
};

export default ArticleHero;
