import React, { createRef, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import styles from "../../styles/NewsBrief.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NewsBrief = (props) => {
  const [data, setData] = useState(props.data);
  let c = 0;
  const { t, i18n } = useTranslation();


  return (
    <div style={{ margin: 50 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        spacing={2}
      >
        {data.map((data, index) => {
          for (let i = c; i < 2; i++) {
            c = 1;
            return (
              <>
                {
                  i ===0
                    ?
                    <Grid
                      item
                      container
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                      className={styles.newsWithBorder}
                    //   spacing={2}
                    >
                      <Grid
                        item
                        container
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        justifyContent="right"
                        alignItems="center"
                        alignContent="center"
                        className={styles.imageSection}
                      >
                        <img src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/newsImages/${data.image}`} className={styles.img} />
                      </Grid>

                      <Grid
                        item
                        container
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                      >
                        <div className={styles.div}>
                          <p className={styles.p}>
                            {i18n.language === "ar"
                              ?
                              data.arabic_title
                              :
                              data.title
                            }
                          </p>
                          <button className={styles.btn}>

                            <Link
                              to={`/news`}
                              style={{ textDecoration: 'none', color: 'white' }}
                            >{t('read_more_word')}</Link>
                          </button>
                        </div>
                      </Grid>
                    </Grid>
                    :
                    <Grid
                      item
                      container
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                    >
                      <Grid
                        item
                        container
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        justifyContent="right"
                        alignItems="center"
                        alignContent="center"
                        className={styles.imageSection}
                      >
                        <img src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/newsImages/${data.image}`} className={styles.img} />
                      </Grid>

                      <Grid
                        item
                        container
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={12}
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                      >
                        <div className={styles.div}>
                          <p className={styles.p}>
                            {i18n.language === "ar"
                              ?
                              data.arabic_title
                              :
                              data.title
                            }
                          </p>
                          <button className={styles.btn}>

                            <Link
                              to={`/news`}
                              style={{ textDecoration: 'none', color: 'white' }}
                            >{t('read_more_word')}</Link>
                          </button>
                        </div>
                      </Grid>
                    </Grid>
                }
              </>
            )

          }
        })}
      </Grid>
    </div>
  );
};

export default NewsBrief;
